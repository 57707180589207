<template lang="pug">
.pt-5.pb-8.block-clip.block-clip--zoom2(:style="companyBackground")
  .d-flex.flex-column.justify-content-center.align-items-center.mx-auto(style="min-height: 90vh; width: 70%")
    h1(style="margin-bottom: 100px;") {{ organisation.name }}
    .row(v-if="organisation.surveys_result && organisation.quote")
      .col-12.col-lg-10.order-2.order-lg-1.position-relative
        .d-flex.justify-content-between.p-3.m-2.flex-column-on-sm.gap-3
          .d-flex.flex-column.partner-box.pt-3
            .text-center
              img.rounded.img-responsive(v-if="organisation.quote_author_photo" :src="organisation.quote_author_photo" :alt="organisation.quote_author_name" loading='lazy' style='width: 100px')
              .rounded(v-else)
                i.fas.fa-user-circle.fa-3x
            small.text-center.mt-2
              | {{ organisation.quote_author_name }}

          .w-100
            .review-quote.d-flex.justify-content-center.align-content-center.flex-column
              .quote-text.hyphens.fs-5
                b {{ organisation.quote }}
            .mt-2.pull-right
              small.fst-italic
                | {{ organisation.quote_author_name }}

            .mt-5.quote-text(v-if="organisation.surveys_result.comment_like_eb")
              | {{ $t('js.components.partner_review.question') }}
              .mt-1.fs-5
                b {{ organisation.surveys_result.comment_like_eb}}
              .mt-2.mb-3.pull-right
                span(v-if="organisation.surveys_result.comment_author_name")
                  small
                    | {{ $t('js.components.partner_review.bewertung_von') }}: {{ organisation.surveys_result.comment_author_name }}

      .col-12.col-lg-2.order-1.order-lg-2
        .text-center.mt-2.p-3
          img.rounded.organisation-logo(:src="organisation.logo_resized" :alt="organisation.name" loading="lazy")
        review-stars.mb-1(:survey="organisation.surveys_result")
        .text-center.mb-3
          i.fas.fa-glass-cheers(style="margin-right: 5px;")
          small.fst-italic
            | {{ $tc('js.components.partner_review.year_membership', { count: organisation.billing_years } ) }}

    .d-flex.flex-column.align-items-center.justify-content-center(v-else-if="organisation.surveys_result")
      .w-100
        .mt-5.quote-text(v-if="organisation.surveys_result.comment_like_eb")
          | {{ $t('js.components.partner_review.question') }}
          .mt-1.fs-5
            b {{ organisation.surveys_result.comment_like_eb}}
          .mt-2.mb-3.pull-right
            span(v-if="organisation.surveys_result.comment_like_eb")
              small
                | {{ $t('js.components.partner_review.bewertung_von') }}: {{ organisation.surveys_result.comment_author_name }}
      .mb-5
        img.rounded.organisation-logo(:src="organisation.logo_resized" :alt="organisation.name" loading="lazy")
      review-stars.mb-1(:survey="organisation.surveys_result" size="big")
      .text-center.mt-3
        i.fas.fa-glass-cheers
        .fst-italic
          | {{ $tc('js.components.partner_review.year_membership', { count: organisation.billing_years } ) }}

    .row(v-else-if="organisation.quote")
      .col-12.col-lg-10.order-2.order-lg-1
        .d-flex.justify-content-between.p-3.m-2.flex-column-on-sm.gap-3
          .d-flex.flex-column.partner-box.pt-3
            .text-center
              img.rounded.img-responsive(v-if="organisation.quote_author_photo" :src="organisation.quote_author_photo" :alt="organisation.quote_author_photo" loading='lazy' style='width: 100px')
            small.text-center.mt-2
              | {{ organisation.quote_author_name }}
          .review-quote.d-flex.justify-content-center.align-content-center.flex-column.w-100
            .ref-quote-text.hyphens.ref-quote-succint.fs-5
              b {{ organisation.quote }}
      .col-12.col-lg-2.order-1.order-lg-2
        .text-center.p-3.m-2
          img.rounded.organisation-logo(:src="organisation.logo_resized" :alt="organisation.username" loading="lazy")
        .text-center.mb-3(v-if="organisation.billing_years")
          i.fas.fa-glass-cheers(style="margin-right: 5px;")
          small.fst-italic
            | {{ $tc('js.components.partner_review.year_membership', { count: organisation.billing_years} ) }}
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ReviewStars from './ReviewStars.vue'

const props = defineProps<{ organisation: Organisation }>()

const companyBackground = computed(() => {
  return {
    'background-color': props.organisation.company_color || '#23788f',
    'color': luminosity(props.organisation.company_color)
  }
})

function luminosity(rgb: any) {
  if (!rgb) rgb = "#000000"
  const r = parseInt(rgb.slice(1, 3), 16)
  const g = parseInt(rgb.slice(3, 5), 16)
  const b = parseInt(rgb.slice(5, 7), 16)
  const value = (0.299 * r + 0.587 * g + 0.114 * b) / 255 * 100

  if (value < 50) return '#f3f3f3'
  return '#000'
}
</script>

<style scoped>
.organisation-logo {
  max-height: 120px;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #e8e8e8;
}
</style>
