import { ref, computed, reactive, watch } from "vue"
const facetTypes = ["sub_job_type", "job_type", "location_names", "remote_type", "salary_available", "working_time", "portal_type_tag"] as const

export type FacetTypes = (typeof facetTypes)[number]

export interface FilterTerm {
  value: string
  count: number
  text: string
}
export type FacetType = Record<FacetTypes, FilterTerm[]>

const chosenFilters = reactive<FacetType>({
  sub_job_type: [],
  job_type: [],
  location_names: [],
  remote_type: [],
  salary_available: [],
  working_time: [],
  portal_type_tag: [],
})

const query = ref<string>("")

const currentFacets = ref<FacetType | null>(null)

const initializeFacets = (initialFacets: FacetType) => {
  currentFacets.value = initialFacets
}

const baseUrl = ref<URL | null>(null)

const setFacetFromSearchParams = (key: FacetTypes, value: string) => {
  const facetValues = currentFacets.value
  if (!facetValues || !value) return

  if (facetTypes.includes(key as FacetTypes)) {
    value.split(",").forEach((v) => {
      const option = facetValues[key as FacetTypes].find((o) => o.value === v)
      // ckecken ob option schon in chosenFilters steht, aka doppelte verhindern
      if (option && !chosenFilters[key as FacetTypes].map((f) => f.value).includes(option.value)) {
        chosenFilters[key as FacetTypes].push(option)
      }
    })
  }
}

const setFacetsFromUrl = (initialUrl: URL, initialParams: Partial<Record<FacetTypes, string>> = {}) => {
  baseUrl.value = initialUrl

  const currentUrl = new URL(window.location.href)

  Object.entries(initialParams).forEach(([key, value]) => {
    setFacetFromSearchParams(key as FacetTypes, value)
  })

  currentUrl.searchParams.forEach((value, key) => {
    setFacetFromSearchParams(key as FacetTypes, value)
  })
}


const searchUrl = computed(() => {
  if (!baseUrl.value) return ""

  const newURL = new URL(baseUrl.value)
  newURL.searchParams.set("q", query.value)

  Object.keys(chosenFilters).forEach((k) => {
    const v = chosenFilters[k as FacetTypes]

    if (v.length > 0) {
      let values = v.map((v) => v.value)

      if (values.length === 0) {
        newURL.searchParams.delete(k)
      } else {
        newURL.searchParams.set(k, values.join(","))
      }
    } else {
      newURL.searchParams.delete(k)
    }
  })
  if (newURL.searchParams.get("page")) {
    newURL.searchParams.delete("page")
  }
  return newURL.toString()
})

export { query, chosenFilters, currentFacets, initializeFacets, setFacetsFromUrl, searchUrl }
