<template lang="pug">
div
  .gradient(v-if="report.old")
    h1.text-primary
      |Diese Auswertung ist veraltet:
    |
    a.btn.btn-lg.btn-secondary.ml-3(:href="retryPath" data-turbo-method="post")
      |Erneut analysieren
  .report-header.bg-primary-gradient.text-white.pt-5.pb-6(:style="{ backgroundImage: 'url(' + background + ')' }")
    .container.homepage-container-tilted-left
      div.milk-glass(style='max-width: fit-content')
        h1.display-5
          | Auswertung
          span(v-if='report.scan.title')   - <q>{{ report.scan.title }}</q>
        p: small
          span(v-if='report.scan') Getestet am: {{ report.scan.last_scan }}
          br
          span(v-if='report.scan.pubdate') Veröffentlicht am: {{ report.scan.pubdate }}

        div.d-flex.gap-5
          code
            a(:href="report.url" target='_blank' rel='noopener nofollow' style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap')
              |{{ report.url.replace('https://', '').replace('http://', '') }}
          .text-right
            a.btn.btn-secondary(:href="retryPath" data-turbo-method="post")
              |Erneut analysieren
        div
          span.badge.bg-dark.badge-top.m-1(v-if="report.scan.title_count")
            |{{ report.scan.title_count }} Wörter im Stellentitel
          span.badge.bg-dark.badge-top.m-1(v-if="report.scan.word_count")
            |{{ report.scan.word_count }} Wörter in der Stellenanzeige
          span.badge.bg-dark.badge-top.m-1(v-if="report.scan.first_p_count")
            |{{ report.scan.first_p_count }} Wörter im ersten Absatz
          span.badge.bg-dark.badge-top.m-1(v-if="report.scan.tag_count")
            |{{ report.scan.tag_count }} gefundene Schlagwörter
  <div></div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"

const props = defineProps<{ report: any; retryPath: string }>()

const background = computed(() => {
  if (props.report.screenshot) {
    return props.report.screenshot
  } else {
    return "/generate_background.svg"
  }
})
</script>

<style scoped>
.milk-glass {
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  color: black;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
