<template lang='pug'>
tr
  template(v-if="true")
    td {{keyword}}
    td
      .progress
        .progress-bar(v-bind:style="{ width: width + '%' }")
          | {{level}}
    td
      span(v-if="suggestKeywords.length === 0"): i
        | Keine Alternativen Begriffe zu diesem Suchwort gefunden.
      span.badge.bg-primary.text-white.m-1.user-select-all(:class="badgeClass" v-for="k in suggestKeywords") {{k.keyword}}
  template(v-else)
    td {{keyword}}
    td.text-center.text-muted(colspan="2") Für dieses Schlagwort haben wir keine Informationen
</template>

<script>
  export default {
    props: ['keyword', 'data', 'type'],
    computed: {
      suggestKeywords(){
        return this.data.suggest.filter(x => x.keyword != this.keyword )
      },
      level(){
        if (!this.data.search_levels) return 0
        return this.data.search_levels[this.type] || 0
      },
      width(){
        return this.level / 5 * 100
      },
      badgeClass(){
        if (this.level < 3){
          return "badge-light"
        }else{
          return "badge-secondary"
        }
      }
    }
  }
</script>
<style scoped>
.badge{
  font-size: 12px;
}
.badge-light {
  color: #868686;
  background-color: #e8e8e8;
}
.icon{
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-top: -3px;
  float: right;
}
</style>
