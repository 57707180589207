<template lang="pug">
.video-player--wrapper(style='' @click='startVideo')
  img(:src='thumbnail' v-if='!active' loading='lazy')
  div.video-player--overlay.text-center(v-if='!active')
    svg(viewBox='0 0 24 24')
      path(:d='mdiPlayCircleOutline' fill='currentColor')
    br
    |{{ $t('js.wizard.video_player.provider_video_abspielen', { provider: provider }) }}
  iframe(v-if='active' :src='url' border='0')
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { mdiPlayCircleOutline } from "@mdi/js"

const active = ref(false)

const props = defineProps<{
  thumbnail: string
  url: string
}>()

const provider = computed(() => {
  const url = props.url
  if (url.includes("youtube.com")) {
    return "YouTube"
  }
  if (url.includes('vimeo.com')) {
    return "Vimeo"
  }
  if (url.includes("watchado")) {
    return "WatchADo"
  }
})

const startVideo = () => {
  active.value = true
}
</script>

<style scoped>
.video-player--wrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  overflow: hidden;
}
iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 0;
  border-radius: 15px;
}
img {
  width: 100%;
  object-fit: cover;
  max-height: 100%;
}
.video-player--overlay {
  color: white;
  position: absolute;
  color: white;
  filter: drop-shadow(1px 1px 6px #333);
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.2);
}

svg {
  height: 48px;
}
</style>
