<script setup lang="ts">
import { ref, watch, computed, reactive } from "vue"

interface FilterTerm {
  value: string
  count: number
  text: string
}

const props = defineProps({
  modelValue: {
    type: Array as PropType<FilterTerm[]>,
    default: () => [],
  },
  options: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
})

// const optionsArray = Object.entries(props.options).map(([key, value]) => ({name: key, count: value}))

const internalSelected = reactive(Array(props.options.length))
const fullRemote = ref()

const emit = defineEmits(["update:modelValue"])

const checked = (option: FilterTerm) => {
  return props.modelValue.findIndex((selected) => selected.text === option.text) !== -1
}

const toggleOption = (option: Object) => {
  const index = props.modelValue.findIndex((selectedOption) => selectedOption.text === option.text)
  if (index === -1) {
    props.modelValue.push(option)
  } else {
    props.modelValue.splice(index, 1)
  }
  emit("update:modelValue", props.modelValue)
}
</script>
<template lang="pug">
div
  p.mb-0.fw-bold(:id="props.title + '-section'") {{props.title}}
  .form-check(v-if="props.options[2]")
    label.form-check-label
      input.form-check-input(@click="toggleOption(props.options[2])" type="checkbox" :id="props.options[2].name" aria-label="Auswahl für Stellen mit Home-Office" :checked="checked(props.options[2])")
      | {{props.options[2].text}} (<b>{{props.options[2].count}}</b>)

  .form-check(v-if="props.options[1]")
    label.form-check-label
      input.form-check-input(@click="toggleOption(props.options[1])" type="checkbox" :id="props.options[1].name + '-filter'" aria-label="Checkbox für Stellen komplett im Home-Office" :checked="checked(props.options[1])")
      | {{props.options[1].text}} (<b>{{props.options[1].count}}</b>)

  .form-check(v-if="props.options[0]")
    label.form-check-label
      input.form-check-input(@click="toggleOption(props.options[0])" :value="props.options[0]" type="checkbox" :id="props.options[0].name" aria-label="Auswahl für Stellen ohne Home-Office" :checked="checked(props.options[0])")
      | {{props.options[0].text}} (<b>{{props.options[0].count}}</b>)



  </template>
<style></style>
