<template lang="pug">
.app-body(:style="{ pagecut: report.old }")
  report-header(:report='report' :retryPath='retryPath')

  .report-body(v-if="report.error")
    .container.mt-5
      .alert.alert-danger
        h4.alert-heading Fehler bei dem Abrufen der URL
        p Leider ist die von Ihnen eingegeben URL nicht abrufbar. Es könnte sein das diese seine nicht mehr existiert oder einen Technischen Fehler aufweist. Bitte überprüfen Sie Ihre Eingabe oder kontaktieren Sie Ihren Technischen Ansprechpartner.
  .report-body(v-else-if="loading")
    .text-center.p-5
      .spinner-border.text-primary(style="width: 10rem; height: 10rem; margin-bottom: 10px; margin-top: 10px;")
      h3.text-primary Ihre Auswertung wird erstellt!

  template(v-else)
    .bg-primary-gradient.text-white.pt-5.pb-5
      .container.homepage-container-tilted-left
        nav.navbar.navbar-expand-lg.navbar-dark.bg-primary.justify-content-center.sticky-top
          ul.navbar-nav
            li.nav-item
              a.nav-link(href="#checks")  Technische Tests
            li.nav-item
              a.nav-link(href="#lighthouse")  Allgemeine Website-Optimierung
            li.nav-item
              a.nav-link(href="#tags")  Verwendete Schlagworte
        .d-block.mt-5#checks
          h2.display-5 Technische Tests
          p Überprüft wichtige Elemente einer Online-Stellenanzeige.
          .container.mt-5(v-if="!report.old")
            .row
              .col-6(v-for="result in checksVisible")
                result-card(:result="result")
          .container.mt-5
            .d-flex.gap-2
              a.btn.btn-outline-info(href="https://forum.empfehlungsbund.de/t/stellenoptimierung-iii-zielgruppenspezifische-ansprache-durch-richtiges-wording/197" target="_blank")
                //- img(:src="academyLogo")
                span.float-right.text-left
                  small EB-Personaler-Forum Beitrag
                  br
                  | Wording in Stellenanzeigen
              a.btn.btn-outline-info(href="https://forum.empfehlungsbund.de/t/stellenoptimierung-i-zielgruppenspezifische-ansprache-durch-keyword-optimierung/196" target="_blank")
                span.float-right.text-left
                  small EB-Personaler-Forum Beitrag
                  br
                  | Keyword-Optimierung
    .bg-medium-gradient.text-white.pt-5.pb-7#lighthouse(v-if="!report.old")
      .container.homepage-container-tilted-left
        h2.display-5 Allgemeine Website-Optimierung
        p Überprüft technische Qualität der Website, z.B. Performance, Barrierefreiheit und Optimierung für mobile Endgeräte.
        lighthouse-comparisons(:token="report.token" :comparisons="checks.lighthouse")
    .bg-white.pt-5.pb-5.block-clip.block-clip--zoom2#tags(v-if="!report.old")
      .container.homepage-container-tilted-left
        h2.display-5 Verwendete Schlagworte
        p Folgende Schlagworte wurden in der Stellenanzeige gefunden. Wählen Sie max. 10 Schlagworte aus, um zu sehen, wie beliebt diese als Suchbegriffe bei Nutzern des Empfehlungsbunds sind.
        p Um Ihre Stelle zu optimieren, zeigen wir gängige alternative Bezeichnungen für alle gefundenen Kompetenzen an. Nutzen Sie davon die beliebtesten Suchbegriffe, um Ihre Stellenanzeige einem möglichst großem Publikum zugänglich zu machen.

        keyword-analyze(:report="report" :rootloading="loading")

</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"

import ResultCard from "./ResultCard.vue"
import KeywordAnalyze from "./KeywordAnalyze.vue"
import LighthouseComparisons from "./LighthouseComparisons.vue"
import ReportHeader from "./ReportHeader.vue"

import consumer from "@/utils/consumer"

const props = defineProps<{
  initialReport: any
  retryPath: string
  channelName: string
}>()

const report = ref(props.initialReport)
const checks = computed(() => report.value.scan.results)
const checksVisible = computed(() => Object.entries(checks.value).filter(([name, result]) => name != 'lighthouse').map(([name, result]) => result))
const loading = computed(() => checks.value && checks.value.length < 8 && !report.value.error)

import { useDebounceFn } from '@vueuse/core'
const refreshReport = useDebounceFn(async () => {
  const response = await fetch(`/report/${report.value.token}.json`)
  const data = await response.json()
  report.value = data
}, 1000)

onMounted(() => {
  consumer.subscriptions.create(
    {
      channel: "Turbo::StreamsChannel",
      signed_stream_name: props.channelName,
    },
    {
      received(_data) {
        refreshReport()
      },
    }
  )
})
</script>

<style scoped>
.pagecut {
  position: relative;
  overflow: hidden;
  height: 1500px;
}

.gradient {
  position: absolute;
  z-index: 9999;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1500px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(70%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

section.bg-light {
  margin-top: 50px;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

.badge-top {
  padding: 5px !important;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  header.masthead h1 {
    font-size: 3rem;
  }
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#checks,
#lighthouse,
#tags {
  scroll-margin-top: 100px;
}

a.nav-link {
  cursor: pointer;
}

.app-body {
  scroll-behavior: smooth;
}</style>
