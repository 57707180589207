import Automount from "~/utils/automount"
import EbLivestream from "./EbLivestream.vue"
import EmpfehlungsgraphWrapper from "./EmpfehlungsgraphWrapper.vue"
import EventCountdown from "./EventCountdown.vue"
import BerufeSelector from "./BerufeSelector.vue"
import ProfileFormStepper from "@/talents/creation_form/ProfileFormStepper.vue"
import Profile from "@/talents/profile/Profile.vue"
import ContactCard from "./ContactCard.vue"
import PricesApp from "./PricesApp.vue"
import WizardCompanyList from "@/wizard/OrganisationList.vue"
import WizardLocation from "@/wizard/Location.vue"
import WizardPortalType from "@/wizard/PortalType.vue"
import ApplyCard from "@/components/apply/ApplyCard.vue"
import ReferencesApp from "./references/ReferencesApp.vue"
import GoogleTagManagerConsent from "./GoogleTagManagerConsent.vue"
import LoginEmail from "@/components/LoginEmail.vue"
import JobApplicationPreview from "@/talents/profile/components/JobApplicationPreview.vue"
import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/material.css"
import "tippy.js/animations/shift-away.css"
import i18n from "@/utils/i18n"
import Toast from "vue-toastification"
import 'vue-toastification/dist/index.css'
import FairekarriereWidget from "@/wizard/components/FairekarriereWidget.vue"
import RejectSurvey from "@/talents/profile/components/RejectSurvey.vue"
import JobList from "@/wizard/JobList.vue"
import ShoppingCart from "@/components/shopping-cart.vue"
import CartOverview from "@/components/cart/cart-overview.vue"
import VideoPlayer from "@/wizard/VideoPlayer.vue"
import BookmarkButton from "@/components/bookmark-button.vue"
import SearchFilterWizard from "@/components/search/filter-wizard.vue"
import OrganisationBadges from "@/wizard/components/OrganisationBadges.vue"
import PortalTypeIcon from "@/elements/PortalTypeIcon.vue"

import NonGermanIpInterstitial from "@/components/NonGermanIpInterstitial.vue"

const toast = [
  Toast,
  {
    transition: "Vue-Toastification__fade",
    newestOnTop: true
  }
]

const tippy = [
  VueTippy,
  {
    directive: "tooltip",
    component: "tippy",
    componentSingleton: "tippy-singleton",
    defaultProps: {
      theme: "material",
      animation: "shift-away",
      placement: "auto-end",
      allowHTML: true,
    },
  },
]


Automount({
  "eb-livestream": {
    component: EbLivestream,
    plugins: [i18n],
  },
  "eb-graph": {
    component: EmpfehlungsgraphWrapper,
    plugins: [i18n],
  },
  "event-countdown": {
    component: EventCountdown,
    plugins: [i18n],
  },
  "google-tag-manager-consent": {
    component: GoogleTagManagerConsent,
    plugins: [i18n, tippy],
  },
  "berufe-selector": {
    component: BerufeSelector,
    plugins: [i18n],
  },
  "contact-card": {
    component: ContactCard,
    plugins: [i18n],
  },
  "prices-app": {
    component: PricesApp,
    plugins: [i18n, tippy],
  },
  "wizard-organisation-list": {
    component: WizardCompanyList,
    plugins: [i18n, tippy],
  },
  "wizard-location": {
    component: WizardLocation,
    plugins: [i18n, tippy],
  },
  "wizard-portal-type": {
    component: WizardPortalType,
    plugins: [i18n, tippy],
  },
  "portal-type-icon": {
    component: PortalTypeIcon,
    plugins: [i18n],
  },
  "apply-card": {
    component: ApplyCard,
    plugins: [i18n, tippy],
  },
  "references-app": {
    component: ReferencesApp,
    plugins: [i18n],
  },
  "profile-form-stepper": {
    component: ProfileFormStepper,
    plugins: [i18n],
  },
  "profile": {
    component: Profile,
    plugins: [i18n, toast],
  },
  "login-email": {
    component: LoginEmail,
    plugins: [i18n],
  },
  "job-application-preview": {
    component: JobApplicationPreview,
    plugins: [i18n],
  },
  "reject-survey": {
    component: RejectSurvey,
    plugins: [i18n],
  },
  "fairekarriere-widget": {
    component: FairekarriereWidget,
    plugins: [i18n],
  },
  "non-german-ip-interstitial": {
    component: NonGermanIpInterstitial,
    plugins: [i18n],
  },
  "job-list-component": {
    component: JobList,
    plugins: [i18n],
  },
  "shopping-cart": {
    component: ShoppingCart,
    plugins: [i18n],
  },
  "cart-overview": {
    component: CartOverview,
    plugins: [i18n, tippy],
  },
  "video-player": {
    component: VideoPlayer,
    plugins: [i18n, tippy],
  },
  "joblytics-report-app": {
    // component: () => import("@/components/joblytics/ReportApp.vue"),
    component: JoblyticsReportApp,
    plugins: [i18n, tippy],
  },
  "bookmark-button": {
    component: BookmarkButton,
    plugins: [i18n, tippy],
  },
  "search-filter-wizard": {
    component: SearchFilterWizard,
    plugins: [i18n],
  },
  "organisation-badges": {
    component: OrganisationBadges,
    plugins: [i18n],
  },
})
import JoblyticsReportApp from "@/components/joblytics/ReportApp.vue"
