<template lang='pug'>
form.price-form(@submit.preven.stop='emit("submit")')
  label.form-label.text-center.fit-content.mx-auto.d-block(for='mitarbeiter')
    | {{ $t('js.components.prices_app.anzahl_der_mitarbeiter') }}
  .input-group.input-group-lg.mx-auto(style='max-width: fit-content')
    input.form-control.form-control-lg(
      name='mitarbeiter'
      type='number'
      v-model='proxyMitarbeiter'
      step='1' min='1'
      max='100000' @keyup.enter='emit("submit")'
      style='max-width: 150px'
    )
    span.input-group-text
      | {{ $t('js.components.prices.form.mitarbeiter_kopfe') }}
  p.help-block.text-center
    | {{ $t('js.components.prices_app.festangestellten_mitarbeit') }}
  radio-group.my-5.text-left(v-model='proxyHasBms' :options='bmsOptions' :label="$t('js.components.prices.form.verwenden_sie_ein_eigenes')" name='hasHms')

  collapse-transition
    div.my-5.text-left(v-if='hasBms === true')
      label.fs-5(v-html="$t('js.components.prices.form.benotigen_sie_einen_strong')")
      checkbox.my-2(v-model='proxyNeedsBmsJobs' name='needsCralwer')
        p.fs-6(v-html="$t('js.components.prices.form.ich_wunsche_dass_die_stron')")
      checkbox.my-2(v-model='proxyNeedsBmsApplicants' name='needsBms')
        p.fs-6(v-html="$t('js.components.prices.form.ich_wunsche_die_automatisi')")

      radio-group.my-5(v-model='proxyCanChangeRejection' :options='rejectionOptions' :label="$t('js.components.prices.form.konnen_sie_prinzipiell_ihr')" name='canChangeRejection')

  <!-- button.btn.btn-outline-light(@click.prevent.stop='emit("submit")' :disabled='!mitarbeiter') -->
  <!--   | {{ $t('js.components.prices_app.preis_berechnen') }} -->
</template>

<script lang='ts' setup>
import { computed, watch } from 'vue'
import { RadioGroup, Checkbox } from "~/elements"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import { useI18n } from 'vue-i18n'

const { t: $t } = useI18n()

const emit = defineEmits(['submit', 'update:mitarbeiter', 'update:hasBms', 'update:needsBmsJobs', 'update:needsBmsApplicants', 'update:canChangeRejection'])
const props = defineProps<{
  mitarbeiter: number | null,
  hasBms: boolean | null,
  needsBmsJobs: boolean | null,
  needsBmsApplicants: boolean | null,
  canChangeRejection: boolean | null
}>()

const proxyMitarbeiter = computed({
  get: () => props.mitarbeiter,
  set: (value) => emit('update:mitarbeiter', value)
})
const proxyHasBms = computed({
  get: () => props.hasBms,
  set: (value) => emit('update:hasBms', value)
})
const proxyNeedsBmsJobs = computed({
  get: () => props.needsBmsJobs,
  set: (value) => emit('update:needsBmsJobs', value)
})
const proxyNeedsBmsApplicants = computed({
  get: () => props.needsBmsApplicants,
  set: (value) => emit('update:needsBmsApplicants', value)
})
const proxyCanChangeRejection = computed({
  get: () => props.canChangeRejection,
  set: (value) => emit('update:canChangeRejection', value)
})

watch(proxyHasBms, (value) => {
  if (value === false) {
    proxyNeedsBmsJobs.value = null
    proxyNeedsBmsApplicants.value = null
    proxyCanChangeRejection.value = null
  }
})


const bmsOptions: FormOption[] = computed(() => [
  { value: true, text: $t('js.components.prices.form.bms_option.ja') },
  { value: false, text: $t('js.components.prices.form.bms_option.nein') },
])
const rejectionOptions: FormOption[] = computed(() => [
  { value: true, text: $t('js.components.prices.form.rejection_option.ja') },
  { value: false, text: $t('js.components.prices.form.rejection_option.nein') },
])

</script>

<style>
.price-form .form-check-input:checked {
  background-color: #30a2bf;
}

.price-form .form-label {
  font-size: 20px;
}

.price-form .form-check {
  margin-bottom: 15px;
  line-height: 1.5;
}
</style>
<style scoped>
:deep strong {
  color: var(--eb-accent-300);
}
</style>
