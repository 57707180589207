<template lang="pug">
.info-box.bg-primary.card(@click='toggleShow' :class='{active: forceShow}')
  .info-box-kontakt
    | {{ $t('js.components.contact_card.kontakt') }}
  .d-flex.gap-3
    div.d-flex.flex-column.align-items-center
      img(v-for='cm in showCm' :src='cm.image' class='info-box__avatar' :title='cm.name + ", " + cm.position' onerror='this.remove()')
      svg(style="width:50px;height:50px" viewBox="0 0 24 24" v-if='showCm.length == 0')
        path(fill="currentColor" d="M12 3C6.5 3 2 6.6 2 11C2 13.2 3.1 15.2 4.8 16.5C4.8 17.1 4.4 18.7 2 21C4.4 20.9 6.6 20 8.5 18.5C9.6 18.8 10.8 19 12 19C17.5 19 22 15.4 22 11S17.5 3 12 3M12 17C7.6 17 4 14.3 4 11S7.6 5 12 5 20 7.7 20 11 16.4 17 12 17M12.2 6.5C11.3 6.5 10.6 6.7 10.1 7C9.5 7.4 9.2 8 9.3 8.7H11.3C11.3 8.4 11.4 8.2 11.6 8.1C11.8 8 12 7.9 12.3 7.9C12.6 7.9 12.9 8 13.1 8.2C13.3 8.4 13.4 8.6 13.4 8.9C13.4 9.2 13.3 9.4 13.2 9.6C13 9.8 12.8 10 12.6 10.1C12.1 10.4 11.7 10.7 11.5 10.9C11.1 11.2 11 11.5 11 12H13C13 11.7 13.1 11.5 13.1 11.3C13.2 11.1 13.4 11 13.6 10.8C14.1 10.6 14.4 10.3 14.7 9.9C15 9.5 15.1 9.1 15.1 8.7C15.1 8 14.8 7.4 14.3 7C13.9 6.7 13.1 6.5 12.2 6.5M11 13V15H13V13H11Z")

    div.text-white.d-flex.flex-column.gap-1
      .flex-grow-1: .d-flex.flex-column.gap-2
        p.h5.mb-0
          | {{ $t('js.components.contact_card.kontakt') }}
        a.text-white(href='mailto:beratung@pludoni.de')
          svg.me-1(style="width:20px;height:20px" viewBox="0 0 24 24")
            path(fill="currentColor" d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z")
          |
          | beratung@pludoni.de
        a.text-white(href='tel:+4935128792370')
          svg.me-1(style="width:20px;height:20px" viewBox="0 0 24 24")
            path(fill="currentColor" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z")
          |
          | 0351 / 28792370
        a.text-white(href='https://www.linkedin.com/showcase/15162148/admin/dashboard/')
          svg.me-1(style="width:20px;height:20px" viewBox="0 0 24 24")
            path(fill="currentColor" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z")
          |
          | linkedin.com
      div.btn-overlap-group.mt-3
        a.btn.btn-accent.btn-sm(href='/online-meeting')
          | {{ $t('js.components.contact_card.termin') }}
        a.btn.btn-outline-light.btn-sm.align-items-center.d-flex(href='/kontakt')
          | {{ $t('js.components.contact_card.kontakt') }}

</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"
import { useSessionStorage } from "@vueuse/core"

type CommunityManagerStub = {
  name: string
  position: string
  image: string
}

const communityManagers = useSessionStorage<CommunityManagerStub[]>("cms", [])

const forceShow = ref(false)

const toggleShow = () => {
  forceShow.value = !forceShow.value
}

const showCm = computed(() => {
  // return 3 random CommunityManager
  // take 3 random
  return communityManagers.value.slice(0, 3)
})

onMounted(() => {
  if (communityManagers.value.length == 0)
    fetch("/arbeitgeber/api/community_managers")
      .then((res) => res.json())
      .then((data) => {
        communityManagers.value = data
      })
})
</script>

<style scoped lang='scss'>
$width: 325px;
$border-color: darken(#bf436f, 5%);
$padding: 20px;
$gap: 16px;
/* gap-2 */

.info-box {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: $padding;
  padding-right: $padding;
  box-shadow: 0 0 2rem $border-color;
  border-radius: 15px;
  border: 1px solid $border-color;
  position: fixed !important;
  top: 100px;
  width: $width;
  z-index: 9999;
  right: -$width + 50px + $padding + $gap;
  transition: right 0.3s ease-out;
}

.info-box:hover,
.info-box:focus,
.info-box.active {
  right: -10px;
}

img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 1px solid $border-color;
  box-shadow: 0 0 10px #fff5;
}

.info-box__avatar:nth-child(1) {
  position: relative;
  top: 15px;
  transition: top 0.3s ease-out;
}

.info-box__avatar:nth-child(2) {
  position: relative;
  top: 0;
  transition: top 0.3s ease-out;
  z-index: 1;
}

.info-box__avatar:nth-child(3) {
  position: relative;
  top: -15px;
  transition: top 0.3s ease-out;
  z-index: 1;
}

.info-box:hover .info-box__avatar,
.info-box:focus .info-box__avatar,
.info-box.active .info-box__avatar {
  top: 0;
}

.info-box-kontakt {
  color: #ddd;
  transform: rotate(-90deg);
  position: absolute;
  left: -20px;
  top: 85px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 1px;
}
</style>
