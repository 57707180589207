<template lang="pug">
div
  template(v-if="!showFinish")
    simple-page-wrapper(
      :pages='pages'
      :fields='prefillForm'
      v-model="onboardingForm.has_prefill"
      @update:prefill="handleInput"
      @invalid-next="checkInvalidNext"
      v-if='!showFinish'
    )
      template(v-slot:page(0))
        CompetencesStep(ref="first")
      template(v-slot:page(1))
        JobTitleStep(ref="second")
      template(v-slot:page(2))
        WorkPermitStep(ref="third")
      template(v-slot:page(3))
        additional-info-step(ref="fourth")
      template(v-slot:page(4))
        EmailStep(ref="fifth")
      template(v-slot:last)
        button.btn.btn-accent.btn-xl.border-white(
          @click.prevent='submit()'
          :disabled="isSubmitting"
        )
          | {{ $t('js.components.talent_profile.stepper_steps.register') }}
    transition(name="fade")
      response-modal(v-model="showResponseModal" :error="error")
  template(v-else)
    finish-hint
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, nextTick } from 'vue'
import useError from "@/utils/unwrapError"

import SimplePageWrapper from '@/elements/SimplePageWrapper.vue'
import JobTitleStep from './steps/JobTitleStep.vue'
import CompetencesStep from './steps/CompetencesStep.vue'
import WorkPermitStep from './steps/WorkPermitStep.vue'
import AdditionalInfoStep from './steps/AdditionalInfoStep.vue'
import EmailStep from "./steps/EmailStep.vue"
import ResponseModal from './components/ResponseModal.vue'
import FinishHint from './FinishHint.vue'

import { talentsCreationPath, talentsLoginPath } from "@/routes"
import csrfToken from "@/utils/csrfToken"
import { form, onboardingForm, resetForm, resetOnboardingForm } from "./talents_form"

import { useI18n } from "vue-i18n"
const { t } = useI18n()

const { error, reset: resetError, isSubmitting } = useError()

const props = defineProps<{
  prefillForm: TalentProfile | {}
  portalType: portaltype | null
}>()

const first = ref<PageWrapper>({ valid: false, touchFields: () => { } })
const second = ref<PageWrapper>({ valid: false, touchFields: () => { } })
const third = ref<PageWrapper>({ valid: false, touchFields: () => { } })
const fourth = ref<PageWrapper>({ valid: false, touchFields: () => { } })
const fifth = ref<PageWrapper>({ valid: false, touchFields: () => { } })

const isValid = (page: any) => {
  if (page.value) return page.value.valid
  return false
}

const pages = computed(() => {
  return [
    { title: t('js.components.talent_profile.stepper_steps.first'), isValid: isValid(first) },
    { title: t('js.components.talent_profile.stepper_steps.second'), isValid: isValid(second) },
    { title: t('js.components.talent_profile.stepper_steps.third'), isValid: isValid(third) },
    { title: t('js.components.talent_profile.stepper_steps.fourth'), isValid: isValid(fourth) },
    { title: t('js.components.talent_profile.stepper_steps.fifth'), isValid: isValid(fifth), },
  ]
})

const checkInvalidNext = (page: number) => {
  const pages = [first, second, third, fourth, fifth]
  const currentPage = pages[page]
  currentPage.value.touchFields()
}

const showResponseModal = ref<boolean>(false)
const showFinish = ref<boolean>(false)

async function submit() {
  fifth.value.touchFields()
  if (!isValid(fifth)) return

  const path = talentsCreationPath()
  var work_permit = "unlimited"
  const citizenship = onboardingForm.value.citizenship
  if (citizenship === "work_permit_no") work_permit = "no_work_permit"

  let { competences, ...talents_profile_form } = form.value

  const reducedCompetences = form.value.competences.map((competence: CompetenceInput) => {
    return {
      competence_id: competence.competence_id,
      experience_level: competence.experience_level,
      is_important: competence.is_important,
      custom_competence: competence.custom_competence
    }
  })

  talents_profile_form['competences'] = reducedCompetences
  talents_profile_form['work_permit'] = work_permit

  resetError()
  const resp = fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      talents_profile_creation_form: talents_profile_form
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        showResponseModal.value = true
        error.value = data.errors.join(", ")
        return
      }
      resetForm()
      resetOnboardingForm()
      showFinish.value = true
      isSubmitting.value = false
    })
}

function handleInput(value: string) {
  onboardingForm.value.has_prefill = value

  if (props.portalType) {
    form.value.portal_type = props.portalType
  }

  const obj = Object.entries(props.prefillForm)
  if (obj.length === 0) return

  for (const [key, value] of obj) {
    if (value === null) continue

    //@ts-ignore
    form.value[key] = value
  }
}

onMounted(() => {
  const obj = Object.entries(props.prefillForm)
  if ((obj.length > 0 || props.portalType) && onboardingForm.value.has_prefill === "no") {
    onboardingForm.value.has_prefill = "yes"
  }
})

</script>

<style>
.p-m {
  padding: 3rem;
}

.my-flex {
  max-height: 250px;
}

.custom-badge {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .p-m {
    padding: 1.5rem;
  }

  .my-flex {
    max-height: none;
  }

  .full-width {
    width: 100%;
  }
}

.border-white {
  border: 1px solid #f8f9fa;
}
</style>
