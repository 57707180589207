<template>
  <div>
    <div class="my-4">
      <div v-if="modelValue.length > 0" class="mb-2">
        <div class="text-sm text-gray-500">
          {{ $t("js.components.empfehlungscodes_field.active_codes") }}
        </div>
        <EmpfehlungscodeListItem v-for="code in modelValue" :code="code" @remove="removeCode" :key="code.code" />
      </div>
      <div class="d-flex align-items-baseline">
        {{ $t("js.components.empfehlungscodes_field.did_you_receive_1_before") }}
        <button @click.prevent="modalOpen = !modalOpen" class="btn btn-link">
          {{ $t("js.components.empfehlungscodes_field.did_you_receive_2_button") }}
        </button>
        {{ $t("js.components.empfehlungscodes_field.did_you_receive_3_after") }}
      </div>
    </div>

    <Modal v-model="modalOpen" :title="$t('js.components.empfehlungscodes_field.button')" id="code-modal">
      <p class="my-3" v-html="$t('js.components.empfehlungscodes_field.intro')"></p>
      <input type="text" v-model="code" :placeholder="$t('js.components.empfehlungscodes_field.placeholder')"
        @keyup.enter="addCode" class="form-control form-control-lg" />
      <Checkbox v-model="useReference" class="mt-3 text-gray-900 dark:text-gray-200" v-if="false">
        {{ $t("js.components.empfehlungscodes_field.use_reference") }}
      </Checkbox>
      <Alert v-if="error" variant="danger" class="my-3">
        {{ error }}
      </Alert>
      <template v-slot:footer>
        <Button @click.prevent="addCode" :disabled="code.length < 3 || checking" class="mt-4 mr-2">
          {{ $t("js.components.empfehlungscodes_field.button") }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { Modal, Checkbox, Button, Alert } from "@/elements"
import * as Routes from "~/routes"
import EmpfehlungscodeListItem from "./EmpfehlungscodeListItem.vue"
import csrfToken from "@/utils/csrfToken"

const props = defineProps<{ modelValue: ValidationSucceed[] }>()
const emits = defineEmits(["update:modelValue"])

const modalOpen = ref(false)
const useReference = ref(false)
const code = ref("")
const checking = ref(false)
const error = ref("")

const addCode = () => {
  checking.value = true
  fetch(Routes.jobApplicationsCheckCodePath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      code: code.value,
    }),
  })
    .then((r) => r.json())
    .then((r) => {
      checking.value = false
      error.value = ""
      emits("update:modelValue", [...props.modelValue, { ...r, code: code.value }])
      modalOpen.value = false
    })
    .catch((e) => {
      if (e.response.data && e.response.data.message) error.value = e.response.data.message
      else error.value = "Es ist ein Fehler aufgetreten, Bitte probieren Sie es später erneut."
      checking.value = false
    })
}

const removeCode = (code: ValidationSucceed) => {
  emits(
    "update:modelValue",
    props.modelValue.filter((c) => c.code !== code.code)
  )
}
</script>
