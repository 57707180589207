<template lang="pug">
div(data-turbo='false')
  transition(name='fade')
    div(ref='calculatorRef' v-if='true')
      price-calculator(:prices="prices" :utm='utm')

</template>

<script lang="ts" setup>
import PriceCalculator from './prices/PriceCalculator.vue'
import PriceCard from './prices/PriceCard.vue'
import { Button } from '@/elements'
import { ref } from 'vue'

const props = defineProps<{ prices: PriceListInput, utm: string, singlePrice: number }>()

const priceMode = ref(false)
const calculatorRef = ref<HTMLElement | null>(null)

const togglePriceMode = () => {
  priceMode.value = !priceMode.value
  if (priceMode.value) {
    setTimeout(() => {
      calculatorRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
}

const minPrice = props.prices.empfehlungsbundAngebotstyp.preisliste.levels[0].price.netto

</script>

<style scoped>
.underline {
  text-decoration: underline;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.decoration-dashed {
  text-decoration-style: dashed;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
