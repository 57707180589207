<script setup lang="ts">
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import icons from "@/wizard/portalTypeIcons"

interface FilterTerm {
  value: string
  count: number
  text: string
}

const props = withDefaults(
  defineProps<{
    options: FilterTerm[],
    showCount?: number,
    title: string,
    translate?: boolean,
    prefix?: string,
    modelValue: FilterTerm[],
  }>(),
  {
    showCount: 3,
    translate: false,
    prefix: "0",
  }
)

const getIcon = (portalType: string) => {
  return icons[portalType] || null;
};

const emits = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits("update:modelValue", value)
  },
})

const $t = useI18n().t

const sortedOptions = ref(props.options.sort((a, b) => b.count - a.count).filter((option) => option?.value))
const showTop = 3
const hasMore = computed(() => {
  return sortedOptions.value.length > showTop
})
const extended = ref(false)
const toggleExtended = () => {
  if (extended.value) {
    document.getElementById(props.title + "-section")?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  extended.value = !extended.value
}

const showOptions = computed(() => {
  if (extended.value) return sortedOptions.value
  else return sortedOptions.value.slice(0, showTop)
})


const to_i18n = (text: string | null) => {
  text = text || ""
  text = text.toLowerCase().replace(/ /g, "_")
  return $t(`js.components.filter_wizard.checkboxes.${text}`)
}
</script>

<template lang="pug">
div
  p.mb-0.fw-bold(:id="props.title + '-section'") {{props.title}}
  .form-check(v-for="option in showOptions" :key="option.text")
    .label.form-check-label
      input.form-check-input(
        v-model="proxyValue"
        :value="option"
        type="checkbox"
        :aria-label="'Checkbox für Stellen in ' + option.text"
      )
      template(v-if="getIcon(option.value)")
        img.me-2(v-if='getIcon(option.value)?.svg' :src='getIcon(option.value)?.svg' :alt='option.value' style="width: 15px; aspect-ratio: 1;")
        svg.me-2(v-else fill='currentColor' :viewBox='getIcon(option.value)?.viewbox' style="width: 15px; aspect-ratio: 1;padding-bottom: 3px;")
          path(:d="getIcon(option.value)?.path" fill='inherit')
      | {{props.translate ? to_i18n(option.value || option.text) : option.text}} (<b>{{option.count}}</b>)


  button.mb-1.mt-0.btn.btn-link.collapse-toggle(v-if="hasMore" @click="toggleExtended" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + props.title")
    | {{ !extended ? $t("js.components.filter_wizard.checkboxes.mehr") : $t("js.components.filter_wizard.checkboxes.weniger") }}

</template>
