<template lang="pug">
.bg-white.pt-5.block-clip.block-clip--zoom2.scrollable-section.section(:data-section-title="$t('js.components.partner_review.partnerstimmen')" style="min-height: 65vh;")
  .text-center.mt-6
    h2.display-1.pb-6
      | {{ $t('js.components.partner_review.partnerstimmen_wir_empfehl') }}

  .text-center.mt-6.mb-3
    b
      | {{ tagline }}
  .d-flex.flex-wrap.justify-content-center.gap-3.mb-5
    template(v-for="pt in portalTypes" :key="pt")
      button.btn.btn-secondary.rounded.d-flex.align-items-center.justify-content-center(
        @click="changePortalType(pt)"
        :class="{ active: pt == currentPortalType }"
        style="flex: 1 1 0px"
      )
        | {{ pt }}
  .d-flex.gap-2.align-items-baseline.justify-content-center(v-if="sortedOrganisations.length > 0")
    p
      | {{ $t('js.components.partner_review.sort_by') }}:
    .button-group.mb-3
      button.btn.dropdown-toggle.btn-outline-secondary(type="button" data-bs-toggle="dropdown" aria-expanded="false" style="text-transform: none; border-radius: 0px")
        | {{ sortOptions.find(o => o.value == currentSort).text }}
      ul.dropdown-menu.dropdown-menu-end(style="border-radius: 0px")
        li(v-for="option in sortOptions" :key="option.value")
          .dropdown-item(@click="changeSort(option.value)")
            | {{ option.text }}

template(v-for="organisation in sortedOrganisations" :key="organisation.username")
  reference-section(:organisation="organisation")
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import ReferenceSection from './ReferenceSection.vue'
import { useI18n } from "vue-i18n"

const { t } = useI18n()

type Organisation = {
  username: string,
  quote: string | null,
  quote_power: null | 1 | 2 | 0,
  quote_author_name: string | null,
  quote_author_photo: string | null,
  company_color: string | null,
  membership_type: string,
  name: string,
  portal_types: string[],
  logo_resized: string | null,
  billing_years: number | null,
  surveys_result: {
    average_score?: number,
    comment_recommend?: number,
    stars_answered_on?: date,
    comment_author_name?: string,
    total_satisfaction?: string,
    comment_like_eb?: string
  }
}

const props = defineProps<{
  organisations: Organisation[]
}>()

const taglines = {
  "all": "Alle Branchen",
  "it": "IT und Software",
  "office": "kaufmännischer Bereich, Finanz- und Versicherungsbranche sowie in der öffentlichen Verwaltung",
  "mint": "Maschinenbau, Elektrotechnik, Naturwissenschaften, Technik",
  "sano": "Gesundheitsbranche und Pflegeberufe",
  "lager": "Lagerlogistik, Fahrer, Fuhrpark, Logistik",
  "hoga": "Gastronomie, Hotel, Restaurant, Koch, Freizeit, Sport",
  "bau": "Baustellen, Bauleiter, Gartenbau, Landschaftsbau"
}
const portalTypes = Object.keys(taglines)

const currentPortalType = ref<string>("all")

const tagline = computed(() => taglines[currentPortalType.value])

const organisationsByPortalType = computed(() => {
  if (currentPortalType.value == 'all') return [...props.organisations]

  var result = [...props.organisations].filter((organisation) => {
    return organisation.portal_types.includes(currentPortalType.value);
  })
  return result
})

const organisationsSortedBySurveyDate = computed(() => {
  return [...organisationsByPortalType.value].sort((a, b) => {
    if (!a.surveys_result) return 1;
    if (!b.surveys_result) return -1;

    return new Date(b.surveys_result.stars_answered_on).getTime() - new Date(a.surveys_result.stars_answered_on).getTime()
  })
})

const organisationsSortedBySurveyScore = computed(() => {
  return [...organisationsByPortalType.value].sort((a, b) => {
    if (!a.surveys_result) return 1;
    if (!b.surveys_result) return -1;

    const a_satisfaction = a.surveys_result.total_satisfaction ? a.surveys_result.total_satisfaction : 0

    const b_satisfaction = b.surveys_result.total_satisfaction ? b.surveys_result.total_satisfaction : 0

    return (a_satisfaction - b_satisfaction)
  })
})

const organisationsSortedByName = computed(() => {
  return [...organisationsByPortalType.value].sort((a, b) => a.name.localeCompare(b.name));
})

const currentSort = ref('date')
const sortOptions = computed(() => [
  { value: 'date', text: t('js.components.partner_review.datum') },
  { value: 'alphabet', text: t('js.components.partner_review.firmenname') },
  { value: 'score', text: t('js.components.partner_review.bewertung') }
])

const sortedOrganisations = computed(() => {
  if (currentSort.value == 'date') {
    return organisationsSortedBySurveyDate.value
  }
  if (currentSort.value == 'score') {
    return organisationsSortedBySurveyScore.value
  }
  if (currentSort.value == 'alphabet') {
    return organisationsSortedByName.value
  }
  return organisationsByPortalType.value
})

function changeSort(sort: string) {
  if (sort == currentSort.value) return

  currentSort.value = sort
}

function changePortalType(portalType: string) {
  if (portalType == currentPortalType.value) return

  currentPortalType.value = portalType
}

</script>

<style scoped>
.partner-box {
  width: 150px
}

@media (max-width: 768px) {
  .partner-box {
    width: 100%;
  }
}

.review-quote:before {
  content: "“";
  font-size: 6rem;
  top: -10px;
  left: -17.5px;
  opacity: 30%;
  font-style: italic;
  height: 50px;
  position: relative;
}

.review-card {
  border-radius: 10px;
}

.review-card-body {
  border-radius: 10px;
}

.rounded {
  border-radius: 20px;
}

.ref-quote-author {
  font-size: 1rem;
}

.ref-quote-content {
  min-height: 200px;
}

.active {
  background-color: #bf436f;
  color: #f3f3f3;
}

.btn-secondary {
  max-width: 120px;
}

@media (min-width: 992px) {
  .quote-text {
    margin-left: 15px;
  }
}

@media (max-height: 1020px) {
  .section {
    min-height: 90vh !important;
  }
}

@media (max-height: 700px) {
  .section {
    min-height: 120vh !important;
  }
}

.quote-text {
  font-style: italic;
  font-size: 16px;
}
</style>
