<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  form: ApplicationForm
}>()

const $t = useI18n().t

const personalDataTest = computed(() =>
  Object.values({ ...props.form, country_code: undefined, empfehlungscodes: undefined, question_answers: undefined, files: undefined }).filter(
    (v) => v !== undefined && v !== ""
  )
)

const personalDataEmpty = computed(() => personalDataTest.value.length === 0)
</script>

<template>
  <div class="card mb-3">
    <div class="card-body">
      <div v-if="!personalDataEmpty" class="d-flex justify-content-between">
        <h3 class="h4">{{ $t("js.components.cart_overview.personal_data") }}</h3>

      </div>
      <div v-else>
        <h3 class="h4">{{ $t("js.components.cart_overview.info_missing") }}</h3>
      </div>

      <Transition name="slide-fade">
        <div v-if="!personalDataEmpty">
          <table class="w-100 table">
            <tr v-if="form.first_name || form.last_name">
              <td>Name</td>
              <td class="break-word pl-1">{{ form.first_name }} {{ form.last_name }}</td>
            </tr>
            <tr v-if="form.email">
              <td>E-Mail</td>
              <td class="break-word pl-1">
                {{ form.email }}
              </td>
            </tr>
            <tr v-if="form.telephone">
              <td>Telefon</td>
              <td class="break-word pl-1">
                {{ form.telephone }}
              </td>
            </tr>
            <tr v-if="form.street || form.postcode || form.city">
              <td>Adresse</td>
              <td class="break-word pl-1">{{ form.street }}, {{ form.postcode }} {{ form.city }}</td>
            </tr>
            <tr v-if="form.comment">
              <td>Anschreiben/Kommentar</td>
              <td class="break-word pl-1">
                {{ form.comment }}
              </td>
            </tr>
            <tr v-if="form.files.length > 0">
              <td>Anhänge</td>
              <td class="break-word pl-1">{{ form.files.map((e) => e.filename).join(", ") }}</td>
            </tr>
          </table>
        </div>
      </Transition>
    </div>
  </div>
</template>
<style>
.slide-fade-enter-active {
  transition: all 0.6s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.pl-1 {
  padding-left: 1rem !important;
}

.card {
  background-color: #eee;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
</style>
