<template lang="pug">
.alert.position-relative.mb-5(:class="cssClass" v-if="!proxyValue")
  .d-flex.justify-content-between.gap-3.align-items-center.p-3.flex-column-on-sm
    template(v-if="!props.phoneVerified")
      .mt-2(style="font-size: 1.3rem")
        div
          | {{ $t('js.components.profile.info_banner.hey_willkommen_zurueck') }}
        .mt-1
          | {{ $t('js.components.profile.info_banner.phone_verification_required') }}
        .mt-1
          | {{ $t('js.components.profile.info_banner.click_underneath') }}
      img.banner-icon(:src="smartphone")
    template(v-else)
      .mt-2(style="font-size: 1.3rem")
        div
          | {{ $t('js.components.profile.info_banner.hey_willkommen_zurueck') }}
        .mt-1
          | {{ $t('js.components.profile.info_banner.dein_profil_ist_bereits') }}
      img.banner-icon(:src="medalAchievement")

  button.btn-close(@click.prevent='proxyValue = true')

</template>


<script setup lang="ts">
import { computed } from "vue"
// import Lock from "asset_images/svg/lock.svg"
// import Network from "asset_images/svg/network.svg"
import medalAchievement from "asset_images/svg/medal-achievement.svg"
import smartphone from "asset_images/svg/smartphone.svg"

const props = defineProps<{
  modelValue: boolean
  phoneVerified: boolean
}>()

const emits = defineEmits([
  'update:modelValue'
])

const proxyValue = computed({
  get: () => props.modelValue,
  set: (val) => emits('update:modelValue', val)
})

const cssClass = computed(() => {
  if (!props.phoneVerified) {
    return 'alert-secondary'
  } else {
    return 'alert-success'
  }
})

</script>

<style scoped>
.btn-close {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 0.7rem;
}
.banner-icon {
  width: 100%;
  max-width: 150px;
  margin-right: 10px;
}
</style>
