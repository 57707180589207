<template lang="pug">
.mt-5.card.bg-medium.text-white.neumorphism-on-medium.mx-auto.d-flex.p-m
  .row
    .col-12
      h4.font-light.mb-3
        | {{ $t('js.components.talent_profile.salary_intervals.label') }}
      .row
        .col-7.col-lg-8
          SalaryInputField(
            v-model="form.salary_preference"
            :options="{ currency: 'EUR', currenyDisplay: 'symbol', locale: 'de-DE' }"
          )
        .col-5.col-lg-4
          Select(
            id="salary_intervals"
            v-model="form.salary_interval"
            :options="options['salary_intervals']"
          )

    .col-12: .mt-5
      h4.font-light.mb-3
        | {{ $t('js.components.talent_profile.work_location.label') }}
      work-location(
        v-model="form.locations"
        :work_location_state="form.work_location_state"
        :radius="form.radius"
        @update:radius="form.radius = $event"
        @update:work_location_state="form.work_location_state = $event"
        :showError="!fieldValid('locations') && v$.locations.$dirty"
      )

    .col-12: .mt-5
      h4.font-light.mb-4
        | {{ $t('js.components.talent_profile.motivation.label') }}
      FormInput(
        name="optional_text"
        :label="false"
        type="textarea"
        v-model="form.optional_text"
        :placeholder="$t('js.components.talent_profile.placeholders.motivation')"
      )

</template>
<script lang="ts" setup>
import { provide, computed } from 'vue'
import { Select, FormInput } from "@/elements"
import SalaryInputField from "../components/SalaryInputField.vue"
import WorkLocation from "../components/WorkLocation.vue"

import useTalent from "../talents_form"
import { getOptionValues, useValidations } from "../talents_form"

const { form, v$ } = useTalent()
provide("v", v$);

const fields = ['locations']

const validations = useValidations(fields, v$)

const fieldValid = validations.fieldValid
const primitiveTouch = validations.touchFields
const primitiveValid = validations.valid

const options = {
  salary_intervals: getOptionValues('salary_intervals'),
  //degrees: getOptionValues('degrees'),
};


const valid = computed(() => {
  return primitiveValid.value
})

function touchFields() {
  primitiveTouch()
}

defineExpose({
  valid,
  touchFields,
})
</script>
