<script setup lang="ts">
import { bookmarkJob, removeBookmark, jobIsAdded } from "@/stores/cartStore"
import { Icon } from "@/elements"
import { mdiClose } from "@mdi/js"
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { previousApplicationTo } from "@/components/apply/history"

const props = defineProps<{
  job: CornerCardJob
}>()


const jobUrl = props.job.full_url

const $t = useI18n().t

const title = computed(() => $t("js.components.bookmark_button.aus_merkliste_entfernen"))

const previousAppliedOn = computed(() => {
  const prevAp = previousApplicationTo(props.job.id.toString())
  if (!prevAp) {
    return null
  }

  // format date in locale date form
  const date = new Date(prevAp.applied_on)
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  return date.toLocaleDateString(undefined, options)
})

</script>

<template>
  <div class="job-card position-relative">
    <button class="btn btn-link position-absolute removeButton p-0" :title="title" :aria-label="title"
      @click.stop.prevent="
        jobIsAdded(props.job)
          ? removeBookmark(props.job)
          : bookmarkJob(props.job)
        ">
      <Icon class="icon" :class="{ plus: !jobIsAdded(props.job) }" :path="mdiClose" :title="title" />
    </button>
    <a class="d-inline fs-6 me-4 mb-2 d-block" :href="jobUrl">{{ job.title }}</a>
    <div class="job-card-company-row">
      <small v-if="previousAppliedOn" class="applied_badge badge badge-sm bg-success mt-2">
        {{
          $t("js.components.bookmark_button.beworben", {
            date: previousAppliedOn,
          })
        }}
      </small>
      <div v-else class='d-flex gap-2'>
        <div v-if="job.organisation?.company_color" class="company-color company_color_cube d-inline-block rounded-sm"
          :style="{ backgroundColor: job.organisation?.company_color }"></div>
        <p class="d-inline-block fw-bold fs-6 m-0">
          {{ job.organisation?.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.removeButton {
  top: 0;
  right: 0;
}

.company-color {
  content: "";
  width: 1em;
  height: 1em;
}

.v-mdi {
  transition: rotate 0.2s ease-in-out;
}

.plus {
  rotate: 45deg;
}
</style>
