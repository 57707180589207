<template lang='pug'>
svg(fill='currentColor' :viewBox='pt.viewbox' v-bind="$attrs" alt='icon')
  path(:d="pt.path" fill='inherit')
</template>

<script lang='ts' setup>
import { ref, computed } from 'vue'
import icons from "@/wizard/portalTypeIcons"

const props = defineProps<{ icon: string }>()

const pt = computed(() => {
  return icons[props.icon]
})
</script>

<style scoped>
svg { height: 1em; width: 1em; }
</style>

