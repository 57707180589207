<template lang="pug">
div
  .row
    .col-xl-8.col-12
      div(v-if='organisation.short_description')
        .blockquote.blockquote-homepage.font-light.my-2(
          v-html='organisation.short_description'
          :style='{borderColor: organisation.company_color || "#ccc"}'
        )
      h3.h4.font-light.mt-6
        | {{ $t('js.wizard.components.organisation_details.gesuchte_kompetenzen') }}
      div.d-flex.flex-wrap.gap-2
        span.badge.bg-medium.badge-primary(v-for='c in organisation.competences' :key='c.slug')
          |{{ c.name }}

      div(v-if='organisation.benefits.length > 0')
        h3.h4.font-light.mt-6
          | {{ $t('js.wizard.components.organisation_details.benefits') }}
        div.d-flex.flex-wrap.gap-2
          span.badge.bg-medium.badge-primary(v-for='b in organisation.benefits' :key='b.id')
            |{{ b.title }}

      div.mt-5(v-if='rewards.length > 0 || organisation.fairekarriere_url || (organisation.kanaleo_cxp_award_score && organisation.kanaleo_cxp_award_score > 0)' ref='rewardEl')
        h3.h3.font-light.my-3
          | {{ $t('js.wizard.components.organisation_details.auszeichnungen') }}

        img.d-block.mx-auto(:src='awardUrl' v-if='awardUrl' style='max-width: 500px')
        fairekarriere-widget.shadow(:organisation='organisation' v-if='organisation.fairekarriere_url')


        div.d-flex.gap-3.mt-2.flex-wrap
          reward-card(v-for='reward in rewards' :key='reward.alt' :reward='reward' style='width: 220px')

    .col-xl-4.col-12.text-center
      div(v-if='people.length > 0')
        div.d-flex.gap-3.mt-2.align-items-center.flex-wrap.justify-content-center
          person-card(v-for='person in people' :key='person.id' style='max-width: 200px' :person='person')
      div.my-5
        h3.h5
          | {{ $t('js.wizard.components.organisation_details.weiterfuhrende_links') }}
        .btn-group.mx-auto.justify-content-center.d-flex.fit-content
          a.btn.btn-sm.btn-outline-dark(:href='organisation.xing_url' v-if='organisation.xing_url' target='_blank')
            |XING
          a.btn.btn-sm.btn-outline-dark(:href='organisation.facebook_url' v-if='organisation.facebook_url' :title="$t('js.wizard.components.organisation_details.facebook_profil')" target='_blank')
            Icon(:path='mdiFacebook' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.twitter_url' v-if='organisation.twitter_url' :title="$t('js.wizard.components.organisation_details.twitter_profil')" target='_blank')
            Icon(:path='mdiTwitter' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.linkedin_url' v-if='organisation.linkedin_url' :title="$t('js.wizard.components.organisation_details.linkedin_profil')" target='_blank')
            Icon(:path='mdiLinkedin' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.youtube_url' v-if='organisation.youtube_url' :title="$t('js.wizard.components.organisation_details.youtube_kanal')" target='_blank')
            Icon(:path='mdiYoutube' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.instagram_url' v-if='organisation.instagram_url' :title="$t('js.wizard.components.organisation_details.instagram_profil')" target='_blank')
            Icon(:path='mdiInstagram' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.github_url' v-if='organisation.github_url' :title="$t('js.wizard.components.organisation_details.github_profil')" target='_blank')
            Icon(:path='mdiGithub' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.behance_url' v-if='organisation.behance_url' :title="$t('js.wizard.components.organisation_details.behance_profil')" target='_blank')
            |Behance
          a.btn.btn-sm.btn-outline-dark(:href='organisation.podcast_url' v-if='organisation.podcast_url' :title="$t('js.wizard.components.organisation_details.podcast')" target='_blank')
            Icon(:path='mdiPodcast' :size='16')
          a.btn.btn-sm.btn-outline-dark(:href='organisation.pinterest_url' v-if='organisation.pinterest_url' :title="$t('js.wizard.components.organisation_details.pinterest_profil')" target='_blank')
            |Pinterest
          a.btn.btn-sm.btn-outline-dark(:href='organisation.flickr_url' v-if='organisation.flickr_url' :title="$t('js.wizard.components.organisation_details.flickr_profil')" target='_blank')
            |Flickr
          a.btn.btn-sm.btn-outline-dark(:href='organisation.career_page_url' v-if='organisation.career_page_url' target='_blank' :title="$t('js.wizard.components.organisation_details.karriere_seite')")
            Icon(:path='mdiLinkVariant' :size='16')
  div.mt-5
    h3.h3.font-light.my-3
      | {{ $t('js.wizard.components.organisation_details.standorte') }}
    div
      pl-map(:points='points')


</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import {
  mdiYoutube,
  mdiPodcast,
  mdiInstagram, mdiLinkedin, mdiTwitter, mdiFacebook, mdiGithub, mdiWeb, mdiLinkVariant
} from "@mdi/js"
import Icon from "@/elements/Icon"
import PersonCard from "@/wizard/components/PersonCard.vue"
import RewardCard from "@/wizard/components/RewardCard.vue"
import FairekarriereWidget from "@/wizard/components/FairekarriereWidget.vue"
import PlMap from "@/components/Map.vue"

import { useI18n } from "vue-i18n"
const { locale } = useI18n()

const props = defineProps<{
  organisation: frontend.Organisation
}>()
const points = computed(() => {
  return props.organisation.visible_addresses.map(l => ({ lat: l.latitude, lon: l.longitude, name: l.display_name })).filter(e => e.lat && e.lon && e.name)
})

const rewards = computed(() => {
  return props.organisation.organisation_rewards.filter((e) => !e.preferred_link.includes("faire-karriere"))
})
const rewardEl = ref<HTMLDivElement | null>()

defineExpose({ rewardEl })

const people = computed(() => props.organisation.visible_people.filter((e) => e.role_profile || e.role_fallback))

const awardUrl = computed(() => {
  if (props.organisation.kanaleo_cxp_award_score && props.organisation.kanaleo_cxp_award_score > 0) {
    return `https://www.kanaleo.de/award/${props.organisation.username}/${locale.value || 'de'}/medium_without_scores.svg`
  }
})
</script>

<style scoped>
.blockquote {
  font-size: 1.2rem;
}
</style>
