<template lang="pug">
div
  .loading-progress-bar.mb-5(v-if="loading")
    .loading-progress-bar__progress
  template(v-else)
    h2.mb-3.profile-card-title
      | {{ $t("js.components.talent_profile.job_subscriptions.active_jobs_sub") }}
    .list-group.mb-3(v-if="jobSubscriptions.length > 0")
      .list-group-item.d-flex.justify-content-between.align-items-center.flex-column-on-sm.p-3.gap-2(
        v-for="js in jobSubscriptions"
        :key="js.id"
      )
        .row.flex-grow-1
          .col-12.col-lg-3: .text-center
            span.label.label-domain(v-if="js.domain")
              img.img-fluid(:src="js.domain.logo.same_height" :alt="js.domain.name" style="max-height: 30px;")
            span.label.label-domain(v-else)
              img.img-fluid(:src="EbLogo" alt='Logo von Empfehlungsbund.' style="max-height: 30px;")
          .col-12.col-lg-9
            .w-100.center-on-sm(v-html="js.summary")
        button.btn.btn-danger.btn-sm.full-width-on-sm(@click="unsubscribe(js.id)")
          | {{ $t("js.components.talent_profile.job_subscriptions.cancel") }}
    template(v-else)
      p
        | {{ $t("js.components.talent_profile.job_subscriptions.no_active_jobs_sub") }}
    template(v-if="suggestions.length > 0")
      p
        | {{ $t("js.components.talent_profile.job_subscriptions.suggestions") }}
      .mt-3.d-flex.flex-wrap.justify-content-start.gap-2
        .card.mb-2(v-for="(suggestion, idx) in suggestions" :key="idx" style="cursor: pointer" @click.prevent="subscribe(suggestion, '')"): .card-body
          .text-center(style="color: #103640;")
            | {{ suggestion }}

    .row
      .col-12: .mt-3
        h2.profile-card-title
          | {{ $t("js.components.talent_profile.job_subscriptions.job_search_and_abo") }}

      .col-lg-12.d-none.d-md-block: .mt-3.mb-3
        .card(style='margin: auto; border-radius: 10px;')
          .card-body
            .row
              .col-8.d-flex.justify-content-center.flex-column
                div(v-html="$t('js.components.talent_profile.job_subscriptions.do_you_know_app')")
              .col-4
                .row
                  .col-lg-6: .text-center
                    a(href="https://itunes.apple.com/de/app/empfehlungsbund-jobsuche/id1201282033?mt=8" style="display:inline-block;overflow:hidden")
                      img.mb-2.img-fluid(:src="qrApple" style="max-width: 150px;")
                      img(:src="AppStore" height="35" alt="App Store Icon.")
                  .col-lg-6: .text-center
                    a(href='https://play.google.com/store/apps/details?id=de.pludoni.empfehlungsbundmobile')
                      img.mb-2.img-fluid(:src="qrAndroid" style="max-width: 150px;")
                      img(:src="GooglePlay" height="35" alt="Google Play Icon.")

      .col-12: .mt-3.mb-3
        .jobs.jobs-search(style="border-radius: 10px;")
          p.text-center
            | {{ $t("js.components.talent_profile.job_subscriptions.stellen_durchsuchen_und_we") }}
          .row
            .col-12.col-sm-4
              input.form-control(
                v-model="q"
                type='text'
                name='q'
                :placeholder="$t('js.components.talent_profile.job_subscriptions.placeholder_q')"
              )
              span.help-block
                | {{ $t('js.components.talent_profile.job_subscriptions.search_q_help') }}
            .col-12.col-sm-4
              input.form-control(
                v-model="position"
                type='text'
                name='position'
                :placeholder="$t('js.components.talent_profile.job_subscriptions.placeholder_position')"
              )
              span.help-block
                | {{ $t('js.components.talent_profile.job_subscriptions.search_position_help') }}
            .col-12.col-sm-4.text-center
              button.btn.btn-primary.btn-empfehlungsbund.btn-block.w-100(@click="buildSearchURL()")
                | {{ $t('js.components.talent_profile.job_subscriptions.search_btn') }}

            .col-12: .mt-3
              button.btn.btn-lg.btn-accent.w-100(@click="subscribe(q, position)" :disabled="q.length === 0")
                |  {{ $t('js.components.apply.apply_succeed.subscribe.button', {query: q}) }}

          collapse-transition: .mt-3(v-if="searchURL.length > 0")
            iframe(
              :src="searchURL"
              width="100%"
              height="1000px"
              frameborder="0"
              allowtransparency="true"
              style="background: #FFFFFF;"
            )

</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { talentsProfileJobSubscriptionsPath, jobSubscriberJobSubscriptionPath, jobsEmbedPath, talentsEmailAboPath } from "@/routes"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import EbLogo from "asset_images/logos/logo-empfehlungsbund-ohne-favicon.png"
import AppStore from "asset_images/badges/app-store.svg"
import GooglePlay from "asset_images/badges/google-play.svg"
import qrAndroid from "asset_images/qr_codes/qr_code_android.svg"
import qrApple from "asset_images/qr_codes/qr_code_apple.svg"
import csrfToken from "@/utils/csrfToken"
import { profile } from "@/talents/profile/profile"

const jobSubscriptions = ref<any[]>([])
const suggestions = ref<string[]>([])
const token = ref<string>('')
const q = ref<string>('')
const position = ref<string>('')
const loading = ref<boolean>(false)
const error = ref<boolean>(false)

function unsubscribe(jobSubscriptionId: number) {
  const path = jobSubscriberJobSubscriptionPath(token.value, jobSubscriptionId, { format: 'json' })

  error.value = false
  fetch(path, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": csrfToken.value || "",
    },
  }).then((response => response.json()))
    .then(data => {
      if (data.status === "ok") {
        getData()
      }
      else {
        error.value = true
      }
      const type = error.value ? "error" : "success"
      emits("submit:makeToast", {
        message: t(`js.components.talent_profile.generic.status.${type}`),
        type: type,
      })
    })
}

const emits = defineEmits(["submit:makeToast"])
import { useI18n } from "vue-i18n"
const { t } = useI18n()

function subscribe(query: string, pos: string) {
  const path = talentsEmailAboPath()
  const params = {
    email: profile.value.data.email,
    query: query,
    position: pos,
  }

  error.value = false
  fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      job_subscription_form: params
    }),
  }).then((response => response.json()))
    .then(data => {
      q.value = ''
      position.value = ''
      if (data.error) {
        error.value = true
      }
      else {
        getData()
      }
      const type = error.value ? "error" : "success"
      emits("submit:makeToast", {
        message: t(`js.components.talent_profile.generic.status.${type}`),
        type: type,
      })
    })
}

const searchURL = ref<string>('')
function buildSearchURL() {
  const params = {
    q: q.value,
    position: position.value,
  }
  const path = jobsEmbedPath(params)
  searchURL.value = path
}

const getData = () => {
  const path = talentsProfileJobSubscriptionsPath()

  fetch(path)
    .then((response: any) => response.json())
    .then((data: any) => {
      jobSubscriptions.value = data.job_subscriptions
      token.value = data.token
      suggestions.value = data.suggestions
    })
}

onMounted(() => {
  getData()
})
</script>
