import { useStorage, debounceFilter } from '@vueuse/core'

const contents : Content[] = [
  { name: "empty", title: "Leer", component: "Empty" },
  { name: "language_skills", title: 'js.components.profile.tables.sprachkenntnisse_bearbeiten', component: "EditLanguage" },
  { name: "competence_levels", title: 'js.components.profile.tables.kompetenzen_bearbeiten', component: "EditCompetence" },
  { name: "job_titles", title: 'js.components.profile.tables.bearbeiten', component: "EditJobTitle" },
  { name: "certificates", title: 'js.components.profile.tables.zertifikat_bearbeiten', component: "EditCertificate" },
  { name: "profile_preview", title: 'js.components.profile.tables.profile_preview', component: "ProfilePreview" },
  { name: "profile_settings", title: 'js.components.profile.tables.profile_settings', component: "ProfileSettings" },
  { name: "phone_verification", title: 'js.components.profile.tables.phone_verification', component: "PhoneVerification" },
]

const modalContent = useStorage<{ show: Boolean, content_name: string }>('modal-content-cache', {
  show: false,
  content_name: 'empty'
}, sessionStorage, { eventFilter: debounceFilter(500) })

function setModalContent(content_name: string) {
  modalContent.value.show = true
  modalContent.value.content_name = content_name
}

export { contents, modalContent, setModalContent }
