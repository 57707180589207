import { useSessionStorage } from "@vueuse/core"
import { watch } from "vue"
import csrfToken from "@/utils/csrfToken"
import { applicantUsageFeedbackPath } from "@/routes"

type FeedbackStore = {
  cartAdded: number
  cartRemoved: number
  relatedClicked: number
  searchCount: number
  searchWithFacetsCount: number
}
type EventType = keyof FeedbackStore

type StoreType = FeedbackStore & { alreadyAnswered: QuestionEnum[] }
export type AnswerType = "stimme_nicht_zu" | "stimme_eher_nicht_zu" | "stimme_eher_zu" | "stimme_zu"

const store = useSessionStorage<StoreType>("feedback", {
  cartAdded: 0,
  cartRemoved: 0,
  relatedClicked: 0,
  searchCount: 0,
  searchWithFacetsCount: 0,
  alreadyAnswered: [],
})

const rules = [
  {
    question: "suche_unkompliziert" as const,
    condition(store: FeedbackStore) {
      return store.searchCount > 4 && store.searchCount % 4 == 0
    },
  },
  {
    question: "gemerkte_anzeigen" as const,
    condition(store: FeedbackStore) {
      return store.cartAdded > 1 && store.cartAdded % 2 == 0
    },
  },
  {
    question: "merkliste_intuitiv" as const,
    condition(store: FeedbackStore) {
      return store.cartRemoved > 1 && store.cartRemoved % 2 == 0
    },
  },
  {
    question: "aehnliche_angebote" as const,
    condition(store: FeedbackStore) {
      return store.relatedClicked > 1 && store.relatedClicked % 2 == 0
    },
  },
  {
    question: "hilfestellungen" as const,
    condition(store: FeedbackStore) {
      return store.searchWithFacetsCount > 1 && store.searchWithFacetsCount % 2 == 0
    },
  },
  {
    question: "filter" as const,
    condition(store: FeedbackStore) {
      return store.searchWithFacetsCount > 2 && store.searchWithFacetsCount % 3 == 0
    },
  },
  {
    question: "oft_gesuchte" as const,
    condition(store: FeedbackStore) {
      return store.searchCount > 2 && store.searchCount % 3 == 0
    },
  },
  // # sternebewertung: nil,
  //   event_count("jobs/search/relevanz") > 1 and event_count("jobs/relevanz") % 2 == 0
  // # sortierung: nil,
  //   event_count("jobs/search/order") > 2 and event_count("jobs/order") % 2 == 0
]

export type QuestionEnum = (typeof rules)[number]["question"]

const drawQuestion = () => {
  return rules.find((e) => e.condition(store.value) && !store.value.alreadyAnswered.includes(e.question))
}

const increment = (event: EventType) => {
  store.value[event] += 1
}

const answer = (question: QuestionEnum, answer: AnswerType) => {
  store.value.alreadyAnswered.push(question)
  return fetch(applicantUsageFeedbackPath(), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({ question, answer }),
  })
}

const ignore = (question: QuestionEnum) => {
  store.value.alreadyAnswered.push(question)
}

type Callback = (question: QuestionEnum) => void

const shouldTriggerQuestion = (fn: Callback) => {
  watch(store.value, () => {
    const rule = drawQuestion()
    if (rule) {
      fn(rule.question)
    }
  })
  const rule = drawQuestion()
  if (rule) {
    fn(rule.question)
    return
  }
}

export { shouldTriggerQuestion, increment, answer, ignore }
