<script setup lang="ts">
import { onMounted, computed, ref } from "vue"
import ExCheckboxGroup from "./wizard/extendable-checkbox-group.vue"
import Checkbox from "@/elements/Checkbox.vue"
import RemoteRadio from "./wizard/remote-radio.vue"
import { useI18n } from "vue-i18n"
import { query, searchUrl, chosenFilters, currentFacets as facets, setFacetsFromUrl, initializeFacets } from "./store"
import type { FacetType, FacetTypes, FilterTerm } from "./store"

const props = defineProps<{
  searchProps: {
    q: string[];
    currentUrl: string;
    subJobType?: string;
  };
  frontendFacets: FacetType;
}>();

const $t = useI18n().t

const idPrefix = computed(() => "fw_" + Math.floor(Math.random() * 10000))

onMounted(async () => {
  query.value = props.searchProps.q.join(" ")
  const currentUrl = new URL(props.searchProps.current_url, window.location.toString())

  initializeFacets(props.frontendFacets)

  setFacetsFromUrl(currentUrl, {})
})

const isLoading = ref(false)
const searchUrlDisabled = computed(() => {
  return isLoading.value
})
const submitQuery = (ev: Event) => {
  isLoading.value = true
  ev.preventDefault()
  Turbo.visit(searchUrl.value)
}
</script>

<template lang="pug">
div
  div(v-if="!facets")
  div.d-flex.flex-column.gap-1.mb-3(v-else)
    ExCheckboxGroup.mb-3(
      v-if="facets.hasOwnProperty('sub_job_type')"
      v-model="chosenFilters.sub_job_type"
      :prefix="idPrefix"
      key="sub_job_type"
      :options="facets.sub_job_type"
      :title="$t('js.components.search.filter_wizard.stellenart')"
      :translate="true"
    )
    ExCheckboxGroup.mb-3(
      v-if="facets.hasOwnProperty('location_names')"
      v-model="chosenFilters.location_names"
      :prefix="idPrefix"
      key="location_names"
      :options="facets.location_names"
      :title="$t('js.components.search.filter_wizard.arbeitsort')"
    )
    ExCheckboxGroup.mb-3(
      v-if="facets.hasOwnProperty('job_type')"
      v-model="chosenFilters.job_type"
      :prefix="idPrefix"
      key="job_type" :options="facets.job_type" :title="$t('js.components.search.filter_wizard.anstellungsart')"
    )
    RemoteRadio.mb-3(
      v-if="facets.hasOwnProperty('remote_type')"
      v-model="chosenFilters.remote_type"
      key="remote_type"
      :options="facets.remote_type"
      :title="$t('js.components.search.filter_wizard.homeoffice_arbeitsplatz')" :translate="true"
    )
    ExCheckboxGroup.mb-3(
      v-if="facets.hasOwnProperty('working_time')"
      v-model="chosenFilters.working_time"
      :prefix="idPrefix"
      key="working_time"
      :options="facets.working_time"
      :title="$t('js.components.search.filter_wizard.arbeitszeit')"
    )
    ExCheckboxGroup.mb-3(
      v-if="facets.hasOwnProperty('portal_type_tag')"
      v-model="chosenFilters.portal_type_tag"
      :prefix="idPrefix"
      key="portal_type_tag"
      :options="facets.portal_type_tag"
      :title="$t('js.components.search.filter_wizard.branche')"
      :translate="true"
    )

    div(v-if="facets.hasOwnProperty('salary_available')")
      p.mb-0.fw-bold
        | {{ $t('js.components.search.filter_wizard.gehalt') }}
      Checkbox(
        v-model="chosenFilters.salary_available"
        title="Gehalt"
        :value="facets.salary_available[0]"
      )
        | {{$t("js.components.filter_wizard.only_with_salary")}}

  a.btn.btn-sm.btn-primary.btn-block.d-block.sticky(@click="submitQuery" :href='searchUrl' :disabled='searchUrlDisabled' :class='{ "disabled": searchUrlDisabled}')
    span(v-if="isLoading")
      i.fas.fa-spinner.fa-spin
      | {{$t("js.components.filter_wizard.loading")}}
    span(v-else)
      | {{$t("js.components.filter_wizard.show_results")}}


</template>
<style scoped>
.sticky {
  position: sticky;
  bottom: 5px;
  z-index: 10;
  margin-bottom: 10px;
}
</style>
