<script setup lang="ts">
import { ref, computed, watch } from 'vue'

const props = defineProps({
  count: { type: Number, required: true },
  color: { type: String, default: 'rgb(31, 199, 162)' },
})

const count = ref(0)
const animationActive = ref(false)

watch(
  () => props.count,
  (currentValue, oldValue) => {
    animationActive.value = currentValue > 0
  }
)

</script>
<template>
  <div @animationend="animationActive = false" :class="{ 'job-card-blob-animation': animationActive }" class="bubble"
    :style="{ visibility: props.count != 0 ? 'visible' : 'hidden' }">
    <span>{{ props.count }}</span>
  </div>
</template>
<style scoped lang="scss">
.bubble:first-of-type {
  background-color: var(--bs-info);
}

.bubble {
  background-color: var(--bs-teal);
  border-radius: 50%;
  color: #000;
  width: 2em;
  height: 2em;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.job-card-blob-animation {
  /* wiggle animation */
  -webkit-animation: wiggle 1s ease-in-out;
  animation: wiggle 1s ease-in-out;
}

span {
  font-family: Source Sans Pro;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
  }

  10% {
    -webkit-transform: skewX(-8deg);
  }

  20% {
    -webkit-transform: skewX(7deg);
  }

  30% {
    -webkit-transform: skewX(-6deg);
  }

  40% {
    -webkit-transform: skewX(5deg);
  }

  50% {
    -webkit-transform: skewX(-4deg);
  }

  60% {
    -webkit-transform: skewX(3deg);
  }

  70% {
    -webkit-transform: skewX(-2deg);
  }

  80% {
    -webkit-transform: skewX(1deg);
  }

  90% {
    -webkit-transform: skewX(0deg);
  }

  100% {
    -webkit-transform: skewX(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: skewX(9deg);
  }

  10% {
    transform: skewX(-8deg);
  }

  20% {
    transform: skewX(7deg);
  }

  30% {
    transform: skewX(-6deg);
  }

  40% {
    transform: skewX(5deg);
  }

  50% {
    transform: skewX(-4deg);
  }

  60% {
    transform: skewX(3deg);
  }

  70% {
    transform: skewX(-2deg);
  }

  80% {
    transform: skewX(1deg);
  }

  90% {
    transform: skewX(0deg);
  }

  100% {
    transform: skewX(0deg);
  }
}
</style>
