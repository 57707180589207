<template lang='pug'>
div.mt-3
  .text-center.my-3(v-if='showHeader')
    | {{ $t('js.wizard.job_list.passende_stellenangebote') }}

  ul.list-group.rounded-md
    job-list-item.list-group-item(v-for='job in showJobs' :key='job.id' :job='job' :organisation='organisation')
    li.list-group-item(v-if='jobs.length > show')
      button.btn.btn-link(@click='show = 999')
        | {{ $t('js.wizard.job_list.mehr_jobs_length_show_anze', { jobs_length_show: (jobs.length - show) }) }}
  

      .text-center.mt-5(v-if='organisation.unsolicited_enabled')
        button.btn.btn-primary.btn-sm.p-3(@click.prevent='apply' :style='{ backgroundColor: organisation.company_color }')
          | {{ $t('js.wizard.job_list.initiativ_bewerben') }}

  collapse-transition
    div(v-if='open')
      job-modal.mt-5(:job='job' :organisation='organisation' ref='modal')
      .d-flex.justify-content-end.mt-5
        button.btn.btn-outline-dark(@click.prevent='toggleOpen')
          | {{ $t('js.wizard.job_list_item.stelle_einklappen') }}
</template>

<script lang='ts' setup>
import { ref, computed, nextTick } from 'vue'
import JobListItem from '@/wizard/JobListItem.vue'
import JobModal from "@/wizard/JobModal.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"

const show = ref(3)

const props = defineProps<{
  jobs: frontend.Job[]
  organisation: frontend.Organisation
  showHeader: {
    type: boolean,
    default: true
  }
}>()
const open = ref(false)
const modal = ref<JobModal | null>(null)

const showJobs = computed(() => {
  return props.jobs.slice(0, show.value)
})

const apply = () => {
  open.value = true
  nextTick(() => {
    setTimeout(() => {
      if (modal.value && modal.value.apply) {
        const el = modal.value.apply
        el.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  })
}
</script>

<style scoped>
.list-group-item {
  border-width: 0px;
}
</style>
