<template lang='pug'>
div
  div
    .card
      .card-body
        .alert.alert-primary(v-if="tags.competences && tags.competences.length <= 5")
          |Nicht genügend Schlagwörter gefunden.
        div
          br
          h5 Branche der Stellenanzeige:
          radio-group(
            name='type'
            v-model="type"
            :options="types"
            inline
          )
      div(v-if="tags.competences && tags.competences.length != 0")
        table.table
          thead
            tr
              th Schlagwort
              th(style="width: 200px;") Suchrelevanz
              th Alternative Begriffe zu diesem Suchwort
          tbody
            keyword-card(v-for="k in competences" :keyword="k.main_word" :key="k.main_word_de" :data="k" :type="type")
    br
    br
    .row(v-if="tags.competences && tags.competences.length != 0")
      .col-5
        .card
          .card-body
            h5 Alternatives Schlagwort vergleichen
            br
            form(@submit="add_keyword")
              .input-group.mb-3
                input.form-control(type='text' placeholder="Schlagwort" v-model="keyword")
                button.btn.btn-primary(type='submit' variant="primary" @click="add_keyword" :disabled="keyword.length === 0")
                  |Hinzufügen
      .col
        .card
          .card-body
            h5 Bedeutung der Suchrelevanz
            table.table
              thead
                tr
                  th Suchrelevanz
                  th Bedeutung
                  th Handlungsempfehlung
              tbody
                tr
                  td
                    strong 5
                  td sehr oft gesucht, Hauptschlagwort
                  td Verwenden Sie viele davon, um Zielgruppen zu adressieren.
                tr
                  td
                    strong 4
                  td oft gesucht
                  td Alternatives Hauptschlagwort als Ergänzung verwenden.
                tr
                  td
                    strong 3
                  td mittel gesucht
                  td Schlagwort im Mittelfeld sollten mit Haupt- und Nebenschlagwörtern angereichert werden.
                tr
                  td
                    strong 2
                  td selten gesucht
                  td Alternatives Nebenschlagwort als Ergänzung verwenden.
                tr
                  td
                    strong 1
                  td sehr selten gesucht
                  td Nebenschlagwort - verwenden Sie viele davon, um Randgruppen zu adressieren.
</template>

<script lang='ts' setup>
import KeywordCard from './KeywordCard.vue'
import { RadioGroup } from "@/elements"

import { ref, computed, watch, onMounted } from 'vue'

const props = defineProps<{
  rootloading: boolean,
  report: any
}>()

const type = ref('eb')
const loading = ref(false)

watch(() => props.report, () => {
  if (props.report.scan.tags) {
    loading.value = false
    type.value = props.report.scan?.tags?.best_matching_portal_type || 'eb'
  }
}, { immediate: true })

const tags = computed(() => props.report.scan.tags)
const userCompetences = ref([])
const keyword = ref("",)
const types = ref([
  { text: 'IT', value: 'it' },
  { text: 'OFFICE', value: 'office' },
  { text: 'MINT', value: 'mint' },
  { text: 'SANO', value: 'sano' },
  { text: "HOGA", value: "hoga" },
  { text: 'LAGER', value: 'lager' },
  { text: 'BAU', value: 'bau' },
  { text: 'Andere', value: 'eb' }
])
const competences = computed(() => {
  return tags.value.competences.concat(userCompetences.value)
})
function add_keyword(e) {
  e.preventDefault()
  const kw = keyword.value
  if (!kw) return
  fetch(`/report/${props.report.token}/tags/check`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ keyword: kw }),
  })
    .then((response) => response.json())
    .then((data) => {
      data.main_word = kw
      keyword.value = ""
      userCompetences.value = [...userCompetences.value, data]
    })
}
</script>

<style scoped lang='scss'>
.btn-sm {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
