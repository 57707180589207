<template lang="pug">
.row
  .col-sm-12(v-if="!comparisons")
    b-progress
      b-progress-bar(:value="lighthouse_progress" :label="`${lighthouse_progress}%`" striped animated)
  .col-sm-3.text-dark(v-else v-for="c in page_comparisons")
    .card
      highcharts(:options="chartOptions(c)" :highcharts="hcInstance" style='height: 150px')
      .card-body.mt-0
        h6.card-title.font-bold {{ c.title }}
        p.card-text {{ c.text }}
  .col-sm-12(v-if="comparisons")
    a.mt-5.btn.btn-secondary.btn-lg.btn-block(:href="'/report/' + token + '/mobile-report'")
      |Vollständigen <q>Lighthouse</q>-Bericht anzeigen
    br
    br
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"

import { Chart as Highcharts } from 'highcharts-vue'
import hcInstance from '@/utils/highcharts'

const props = defineProps<{
  comparisons: any
  token: string
}>()

const lighthouse_progress = ref(0)
onMounted(() => {
  if (!props.comparisons) {
    const interval = setInterval(() => {
      if (lighthouse_progress.value < 99) {
        lighthouse_progress.value += 1
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }
})

function chartOptions(c): Highcharts.Options {
  return {
    chart: {
      type: 'solidgauge',
      margin: [0, 5, 5, 0],

    },
    title: undefined,
    legend: { enabled: false },
    credits: { enabled: false },
    yAxis: {
      stops: [
        [0.0, '#DF5353'], // red
        // orange at 0.3
        [0.3, "#DDDF0D"],
        [0.6, '#DDDF0D'], // yellow
        [0.9, '#55BF3B'], // green
      ],
      tickPositions: [],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      min: 0,
      max: 100,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: true,
          format: "{y}%",
          y: 5,
          borderWidth: 0,
        },
        borderRadius: 3,
      },
    },
    tooltip: {
      enabled: false
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#fafafa',
        borderRadius: 5,
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    series: [
      {
        type: 'solidgauge',
        color: c.color,
        data: [Math.round(c.score)],
      },
    ],
  }
}

const page_comparisons = computed(() => {
  let color
  const config = {
    performance: {
      title: "Performance",
      text: "Wie schnell lädt Ihre Seite, z.B. Leistung des Webservers, Komplexität des Javascripts, geringer Batterieverbrauch.",
    },
    accessibility: { title: "Barrierefreiheit", text: "Zugänglichkeit für Nutzer mit Einschränkungen, Nutzung von Screenreadern usw." },
    "best-practices": {
      title: "Empfohlene Vorgehensweise",
      text: "Nutzung von Industriestandards bei der Programmierung bzw. Konfiguration des Webservers",
    },
    seo: { title: "Suchmaschinenoptimierung", text: "Nutzung aller empfohlener Meta-Tags zur besseren Auffindbarkeit" },
  }
  return props.comparisons
    .filter((x) => Object.keys(config).indexOf(x.id) != -1)
    .map((x) => {
      if (x.score < 49) {
        color = "#dc3545"
      } else if (x.score >= 49 && x.score < 89) {
        color = "#ffc107"
      } else if (x.score >= 90) {
        color = "#28B62C"
      }
      return { title: config[x.id].title, score: x.score, color: color, text: config[x.id].text }
    })
})
</script>

<style scoped lang="scss">
.card-body {
  min-height: 155px;
}

.lh-card {
  margin-top: 40px;
}

.card-chart-top {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
