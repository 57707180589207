<template lang="pug">
div(v-if='props.event')
  div
    div
      div
        h2
          | {{ props.event.title }}
      p.my-5(v-if='props.event.description' v-html='props.event.description')
      .btn-overlap-group.justify-content-center
        a.btn-accent.btn.btn-lg.text-uppercase(v-if='props.event.register_url' :href="props.event.register_url" target="_blank" rel="noopener noreferrer")
          | {{ $t('js.components.event_countdown.jetzt_anmelden') }}
        a.btn-outline-light.btn.btn-lg.text-uppercase(v-if='props.event.details_link' :href="props.event.details_link" target="_blank" rel="noopener noreferrer")
          | {{ $t('js.components.event_countdown.mehr_erfahren') }}

  .d-flex.mt-3.align-items-baseline.justify-content-between.text-accent-300
    .bfg__time-intro
      | {{ $t('js.components.event_countdown.noch') }}
    .bfg__time-entry
      .bfg__time-entry-number
        | {{ days }}
      | {{ $t('js.components.event_countdown.tage') }}
    .bfg__time-entry
      .bfg__time-entry-number
        | {{ hours }}
      | {{ $t('js.components.event_countdown.stunden') }}
    .bfg__time-entry
      .bfg__time-entry-number
        | {{ minutes }}
      | {{ $t('js.components.event_countdown.minuten') }}
    .bfg__time-entry
      .bfg__time-entry-number
        | {{ seconds }}
      | {{ $t('js.components.event_countdown.sekunden') }}
</template>

<script lang="ts" setup>
import { onMounted, ref, onBeforeUnmount } from "vue"

type CountdownEvent = {
  title: string
  description: string
  register_url: string
  details_link: string
  start: string
}

const props = defineProps<{ event: CountdownEvent | null }>()

const event = ref<CountdownEvent | null>(null)

const days = ref("00")
const hours = ref("00")
const minutes = ref("00")
const seconds = ref("00")

const tick = () => {
  if (!event.value) return
  const finaleDate = new Date(event.value.start).getTime()
  var now = new Date().getTime()
  var diff = finaleDate - now
  if (diff < 0) {
    event.value = null
    return
  }

  const d = Math.floor(diff / (1000 * 60 * 60 * 24))
  const h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const s = Math.floor((diff % (1000 * 60)) / 1000)
  days.value = d <= 9 ? "0" + d : d.toString()
  hours.value = h <= 9 ? "0" + h : h.toString()
  minutes.value = m <= 9 ? "0" + m : m.toString()
  seconds.value = s <= 9 ? "0" + s : s.toString()
}

let intervalId: NodeJS.Timer | null = null

onMounted(() => {
  if (props.event) {
    event.value = props.event
    tick()
    intervalId = setInterval(tick, 1000)
  }
})
onBeforeUnmount(() => {
  if (intervalId) clearInterval(intervalId)
})
</script>

<style scoped lang='scss'>
.bfg__time-intro {
  text-align: center;
  font-size: 2.125rem;
}

.bfg__time-entry {
  text-align: center;
  padding: 15px 15px 20px 15px;
  font-size: 1em;
  line-height: 1em;
  text-transform: uppercase;

  &-number {
    font-size: 2.8em;
    line-height: 1.3;
  }
}

h2 {
  font-weight: 300
}

@media (max-width: 768px) {
  .bfg__time-intro {
    font-size: 1.0rem;
  }

  .bfg__time-entry {
    padding: 10px 10px 15px 10px;
    font-size: 0.8em;

    &-number {
      font-size: 1.8em;
    }
  }
}
</style>
