<template lang="pug">
div
  template(v-if="!preview")
    .alert.alert-warning.d-flex.gap-3.justify-content-start.align-items-center
      i.fa.fa-info-circle.fa-2x
      small
        | {{ $t('js.components.profile.hinweis') }}
    .alert(:class="progressValue < 100 ? 'alert-info' : 'd-none'")
      .mb-1
        small(v-html="hint")
      .progress
        .progress-bar(
          role="progressbar"
          :style="{width: progressValue + '%'}"
          :class="bgClass"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        )
  div(v-if="form")
    .row
      .col-12(v-if="isSubmitting && !preview")
        .loading-progress-bar.mt-2.mb-2
          .loading-progress-bar__progress
      .col-12.col-md-6: .mb-4
        FormInput(
          name="first_name"
          type="text"
          v-model="form.first_name"
          :v="v$"
        )
      .col-12.col-md-6: .mb-4
        FormInput(
          name="last_name"
          type="text"
          v-model="form.last_name"
          :v="v$"
        )
      .col-12(v-if="!preview"): .mb-4
        FormGroup(:label="$t('js.components.profile.labels.profile_link_app')" for="profile_link_app")
          ProfileLinkApp(@makeToast="makeToast($event)")

      .col-12: .mb-4
        FormGroup(:label="uploadFieldLabel" for="files")
          .text-muted
            small
              | {{ $t('js.components.profile.labels.select_hint') }}
          UploadedFileListItem(
            v-for="file in form.files"
            :key="file.signed_id"
            :file="file"
            :blob-redirect="true"
            :hideRemove="preview"
            :hideSelect="!preview"
            :selected="fileSelected(file.signed_id)"
            @remove="removeUpload"
            @select="selectFile(file.signed_id)"
          )
          UploadField(
            :max-size="10 * 1024 * 1024"
            @uploaded="addUpload"
          )
        .alert.alert-danger.is-invalid.mt-3(v-if="(v$.files.$invalid || selectedFiles.length === 0)  && v$.files.$dirty")
          | {{ $t('js.components.talent_profile.errors.upload_and_select_file') }}

      .col-12(v-if="!preview"): .mt-3
        .d-flex.justify-content-end.gap-2
          button.btn.btn-primary.btn-lg(
            id="submit_application_profile"
            :disabled="!formChanged || isSubmitting"
            @click="submitWithToast()"
          )
            i.fas.fa-save(style="margin-right: 0.5rem")
            | Speichern
</template>

<script setup lang="ts">
import { computed, ref, provide, onMounted } from "vue"
import { FormInput, FormGroup, UploadField, UploadedFileListItem  } from "@/elements"
import { useSubmit } from "@/talents/profile/profile"
import { applicationProfile } from "@/talents/profile/profile"
import useVuelidate from "@vuelidate/core"
import { minLength } from "@/utils/validators"
import { requiredIf } from "@vuelidate/validators"
import ProfileLinkApp from "@/talents/profile/components/edit/ProfileLinkApp.vue"
import { useI18n } from "vue-i18n"
import { debounceFilter, watchWithFilter } from '@vueuse/core'
const { t } = useI18n()

const { submit, error, isSubmitting, form, formChanged } = useSubmit("application_profile")

const props = withDefaults(defineProps<{
  preview: boolean
}>(), {
  preview: false,
})

watchWithFilter(
  form,
  () => { if(props.preview) submit() },
  { eventFilter: debounceFilter(500), deep: true },
)


const emits = defineEmits(["submit:makeToast"])

function makeToast(type: string) {
    emits("submit:makeToast", {
        message: t(`js.components.talent_profile.generic.status.${type}`),
        type: type,
    })
}

const submitWithToast = () => {
    submit()
    const type = error.value ? "error" : "success"
    makeToast(type)
}

const rules = computed(() => ({
  first_name: {
    required: requiredIf(() => props.preview),
  },
  last_name: {
    required: requiredIf(() => props.preview),
  },
  files: {
    required: requiredIf(() => props.preview),
    minLength: minLength(1) },
}))

const v$ = useVuelidate(rules, form, {})
provide("v$", v$)

const uploadFieldLabel = computed(() => {
  if (props.preview) {
    return t("js.components.profile.labels.select_file")
  }
  return t('js.components.profile.labels.upload')
})
const addUpload = (payload: { file: File, blob: ActiveStorage.Blob }) => {
  form.value.files = [...form.value.files, payload.blob]
  if (props.preview) {
    selectFile(payload.blob.signed_id)
  }
}
const removeUpload = (blob: ActiveStorage.Blob) => {
  form.value.files = form.value.files.filter((file: any) => file.signed_id !== blob.signed_id)
}
const selectedFiles = ref<string[]>([])
function selectFile(signedId: string) {
  const idx = selectedFiles.value.indexOf(signedId)
  if (idx > -1) {
    selectedFiles.value.splice(idx, 1)
  } else {
    selectedFiles.value.push(signedId)
  }
}
function fileSelected(signedId: string) {
  return selectedFiles.value.includes(signedId)
}

onMounted(() => {
  selectedFiles.value = form.value.files.map((file: any) => file.signed_id)
})

const bgClass = computed(() => {
  if (progressValue.value < 50) {
    return 'bg-warning'
  } else {
    return 'bg-success'
  }
})

const progress = computed(() => {
  if (applicationProfile.value.progress) {
    return applicationProfile.value.progress
  }
  return null
})

const progressValue = computed(() => {
  if (progress.value) {
    return progress.value.progress
  }

  return 0
})

const hint = computed(() => {
  if (progress.value && progressValue.value < 100) {
    const fieldStr = progress.value.next_steps.map((step: NextStep) => step.display_name).join(", ")
    return  t(`js.components.progress.missing_steps`, { fields: fieldStr })
  } else {
    return t(`js.components.progress.hint.application_profile`)
  }
})

function touchFields() {
  v$.value.$touch()
}

const valid = computed(() => {
  return !v$.value.$invalid && selectedFiles.value.length > 0 && !error.value
})

defineExpose({
  formChanged,
  form,
  selectedFiles,
  touchFields,
  valid,
})

</script>

<style scoped>
.progress {
  background-color: #dfe1ed;
}
</style>
