<script setup lang="ts">
import { ref, onMounted, watch } from "vue"
import { addCode, validateCode } from "@/stores/cartStore"
import { Icon, Modal, Checkbox } from "@/elements"
import { mdiStarCircleOutline } from "@mdi/js"
import { useI18n } from "vue-i18n"

const $t = useI18n().t

const props = defineProps<{
  linkText?: string
}>()

const emit = defineEmits(["update"])

const modalOpen = ref(false)
const codeInput = ref("")
const msg = ref<ValidationSucceed | null>(null)
const inputField = ref<HTMLElement | null>(null)

const checking = ref(false)
const allowContact = ref(false)
const error = ref()

onMounted(() => {
  setTimeout(() => {
    const el = inputField.value
    if (el) el.focus()
  }, 100)
})

const clickCode = async () => {
  checking.value = true
  const ans = await validateCode(codeInput.value)

  if (ans.status == 'success') {
    error.value = null
    msg.value = ans
    addCode({ ...ans, code: codeInput.value, allowContact: allowContact.value })

    emit("update")

    setTimeout(() => {
      codeInput.value = ""
      modalOpen.value = false
    }, 5000)
  } else if (ans.status != 'success') {
    error.value = ans.message
  }
  checking.value = false
}

watch(
  () => modalOpen.value,
  (val) => {
    if (!val) {
      codeInput.value = ""
      error.value = undefined
      msg.value = null
      checking.value = false
    }
  }
)
</script>

<template lang="pug">
Modal(v-model="modalOpen" size="lg" :title="$t('js.components.codes_modal.title')" id="codes-modal")
  p(v-html="$t('js.components.empfehlungscodes_field.intro')")
  div.alert.alert-danger(v-if='error')
    |{{error}}
  div.alert.alert-success.text-center(v-if='msg')
    .d-flex.gap-2.text-start
      Icon(:path='mdiStarCircleOutline')
      p {{$t('js.components.codes_modal.success_msg', {firma: msg.firma })}}

    img(:src="msg.logo" alt="")

  form(@submit.prevent.stop='clickCode')
    input.form-control.w-100.mb-3(type="text", :placeholder="$t('js.components.codes_modal.save_code_button')", v-model="codeInput" autofocus @keyup.enter='clickCode' ref='inputField' :disabled='checking')

    .d-flex.gap-1.mx-1.mb-3
      Checkbox(v-model="allowContact" name='allowContact')
        |{{$t('js.components.codes_modal.allow_contact')}}
    button.btn.btn-primary.w-100(type="button", @click="clickCode" :disabled='checking')
      .spinner-border.m-0(v-if="checking" role="status")
        span.sr-only
      span(v-else)
        | {{ $t('js.components.codes_modal.save_code_button') }}


a.btn.btn-link(v-if='linkText' @click.prevent.stop="modalOpen= !modalOpen")
  | {{linkText}}
a.mb-2.btn.btn-accent.d-flex.p-2.gap-1.w-100.btn-apply(v-else @click.prevent.stop="modalOpen= !modalOpen")
  Icon(:path="mdiStarCircleOutline")
  span.ms-2
    | {{ $t('js.components.codes_modal.save_code_button') }}



</template>
