<template lang='pug'>
li.list-group-item.mb-4(:class="resultClass")
  .list-header(v-on:click="openCollapse()")
    .icon
      b-spinner(small v-if="result.loading")
      svg(v-else viewBox="0 0 24 24")
        path(:fill="iconColor" :d="iconPath")
    | {{result.title}}
    span.float-right
      svg(style="width:24px;height:24px" viewBox="0 0 24 24")
        path(:fill="iconColor" :d="openIcon")
  .collapse(:id="result.id" :class="{show: open}")
    div(v-if="result.explanation || (!result.success && result.message)")
      .alert.alert-primary(v-if="!result.success") {{result.message}}
      p.card-text(v-html="result.explanation")
</template>

<script>
  export default {
    props: ['result'],
    data(){
      return {
        open: false
      }
    },
    methods: {
      openCollapse(){
        if (this.result.keywords){
          let element = document.getElementById("tags");
          element.scrollIntoView({behavior: "smooth"});
        }else{
          this.open = !this.open
        }
      }
    },
    computed: {
      openIcon(){
        if (this.open){
          return "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
        }else{
          return "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
        }
      },
      iconPath(){
        if (this.result.success){
          return "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" // Check
        }else{
          return "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" // Close
        }
      },
      iconColor(){
        if (this.result.success){
          return "#2db4a7" // Check
        }else{
          return "#eb5a5f" // Close
        }
      },
      resultClass(){
        if (this.result.loading){
          return "is-secondary"
        }else if (this.result.success){
          return "is-secondary"
        }else{
          return "is-primary"
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
.list-header{
  cursor: pointer;
}
.collapse.show{
  margin-top: 15px;
  margin-bottom: 15px;
}
button{
  padding: 0px;
  font-size: 14px;
  border: 0px;
  color: #222;
}
.icon{
  width: 24px;
  height: 24px;
  margin-right: 10px;
  float: left;
}
.is-secondary {
  border-color: #2db4a7;
}
.is-primary {
  border-color: #eb5a5f;
}
</style>
