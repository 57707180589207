<template lang="pug">
div(data-turbo='false')
  .row
    .col
      .card.bg-primary.neumorphism-on-primary.text-white.mb-5
        .card-body
          p.text-left {{ $t('js.components.prices_app.die_empfehlungsbundmitglie') }}
          prices-form(
            v-model:mitarbeiter='form.mitarbeiter'
            v-model:hasBms='form.hasBms'
            v-model:needsBmsJobs='form.needsBmsJobs'
            v-model:needsBmsApplicants='form.needsBmsApplicants'
            v-model:canChangeRejection='form.canChangeRejection'
            @submit='calculatePrice'
          )

    .col.d-flex.justify-content-center
      .fit-content(style='position: sticky; top: 50px')
        price-preview-card#price-cart(:form='form' utm='utm' ref='previewCard' :prices='prices')


modal(id='offer-modal' :title="$t('js.components.prices_app.angebot_erzeugen')")
  create-offer-modal(:offerForm='form')

</template>

<script lang="ts" setup>
import { Modal } from "~/elements"
import { ref, reactive } from "vue"
import PricesForm from './Form.vue'
import CreateOfferModal from './CreateOfferPdfModal.vue'
import PricePreviewCard from './PricePreviewCard.vue'

defineProps<{ prices: PriceListInput, utm: string }>()

const previewCard = ref<typeof PricePreviewCard | null>()

const calculatePrice = () => {
  const el = previewCard.value
  if (!el) return

  el.calculatePrice()
  el.scrollIntoView()
}
const form = reactive<PriceForm>({
  mitarbeiter: null as number | null,
  hasBms: null as boolean | null,
  needsBmsJobs: null as boolean | null,
  needsBmsApplicants: null as boolean | null,
  canChangeRejection: null as boolean | null,
  firma: "",
  rechnungsadresse: "",
})


</script>
