<template lang="pug">
.d-flex.flex-column.align-items-end.gap-2.h-100
  div.d-flex.gap-1.h-100
    span.badge.bg-accent.d-flex.justify-content-center.align-items-center(v-if='organisation.kanaleo_cxp_award_score && organisation.kanaleo_cxp_award_score > 0' @click='emitClick' role='button' v-html='$t("js.wizard.organisation_card.very_good_experience")')

    span.badge.bg-medium.d-flex.flex-column.justify-content-center.align-items-center.gap-2(v-if='organisation.fairekarriere_score' :title="$t('js.wizard.organisation_card.bewertung', { rating: organisation.fairekarriere_score })" v-tooltip @click='emitClick' role='button')
      | {{ $t('js.wizard.organisation_card.bewertung_title') }}
      stars-badge(:stars='organisation.fairekarriere_score')
  div.d-flex.gap-2.align-items-center.flex-wrap(v-if='!isJob')
    span.badge.bg-light.text-dark.d-flex.align-items-center.gap-2(v-for='sector in sectors')
      svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' style='height: 12px; width: 12px;')
        path(:d='mdiFactory')
      | {{ sector.name }}

    span.badge.bg-light.text-dark.d-flex.align-items-center.gap-2(:title="$t('js.wizard.organisation_card.anzahl_mitarbeiter')" v-tooltip)
      svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' style='height: 12px; width: 12px;')
        path(:d='mdiAccountGroup')
      | {{ organisation.company_size }}
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { mdiFactory, mdiAccountGroup } from "@mdi/js"
import StarsBadge from "@/components/StarsBadge.vue"

const props = withDefaults(defineProps<{
  organisation: frontend.Organisation,
  isJob?: boolean
}>(), {
  isJob: false
})
const sectors = computed(() => {
  console.log(props.isJob)
  return props.organisation.sectors.filter((e) => !e.name.includes("sonstig"))
})

const emit = defineEmits(["click"])
const emitClick = () => emit("click")
</script>

<style scoped></style>
