<template>
  <div
    class="badge w-100 d-flex gap-2 flex-row bg-dark align-items-center justify-content-between bg-opacity-50 border-1 border border-dark position-relative">
    <img :src="code.logo" v-if="code.logo" style="max-height: 3em; margin-left: -5px" class="rounded" />
    {{ code.code }}
    <button class="btn btn-light btn-sm" @click="onClick" title="Empfehlungscode entfernen">
      <Icon :path="mdiClose" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from "@/elements"
import { mdiClose } from "@mdi/js"

const props = defineProps<{
  code: ValidationSucceed
}>()

const emits = defineEmits(["remove"])

const onClick = () => {
  emits("remove", props.code)
}
</script>

<style scoped>
.btn-light {
  padding: 0.1rem;
  opacity: 0.8
}
</style>
