<template>
  <div id="cart-outter" ref="cartHolder" class='mx-3'>
    <div id="cart-holder" class="d-flex justify-content-center">
      <div class="button-wrapper">
        <div @click="toggleCart()" id="bubbles-wrapper" class="d-flex justify-content-between">
          <CountBubble :count="codes.length" />
          <CountBubble color="--bs-info" :count="jobs.length" />
        </div>
        <button @click="toggleCart()" class="btn btn-link" title="Merkliste von Stellen">
          <Icon :path="mdiBriefcaseOutline" />
        </button>
      </div>
      <div id="cart-wrapper" class="mx-4 mx-md-0 rounded shadow" v-show="cartOpen">
        <h3>{{ $t("js.components.cart.jobs") }}</h3>
        <ul>
          <li v-if="showJobs.length == 0">
            <div class="text-center">
              <p class="text-muted">
                {{ $t("js.components.cart.jobs_empty") }}
              </p>
            </div>
          </li>
          <li v-else v-for="(job, index) in showJobs" :key="job.id">
            <JobCard :job="job" :class="{ 'strike-through': !jobIsAdded(job) }" />
            <hr v-if="index != showJobs.length - 1" />
          </li>
        </ul>
        <a :href="merklistePath()" class="d-flex btn btn-accent w-100 btn-apply">
          <Icon :path="mdiFormatListBulleted" />
          <span class="ms-2">{{ $t("js.components.cart.to_list") }}</span>
        </a>
        <ul>
          <hr />
          <p v-if="codes.length != 0" class="m-0">
            {{ $t("js.components.cart.active_codes_heading") }}
          </p>
          <div class="d-flex flex-column gap-2 my-2">
            <CodeListItem v-for="code in codes" :code="code" :key="code.code" @remove='removeCode' />
          </div>
          <li class="d-flex gap-3">
            <CodesModal />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { state, jobIsAdded, validateCode, addCode, removeCode } from "@/stores/cartStore"
import CodesModal from "@/components/shopping-cart-codes-modal.vue"
import JobCard from "@/components/job-card-small.vue"
import CodeListItem from "./cart/code-list-item.vue"
import { ref, watch, computed, onMounted } from "vue"
import { Icon } from "@/elements"
import { mdiBriefcaseOutline, mdiFormatListBulleted } from "@mdi/js"
import CountBubble from "@/components/cart/CountBubble.vue"
import { merklistePath } from "@/routes"
import { useI18n } from "vue-i18n"

const $t = useI18n().t

const props = defineProps<{
  initialCode?: string
}>()

onMounted(() => {
  if (props.initialCode) {
    validateCode(props.initialCode).then((ans) => {
      if (ans.status == "success") {
        addCode({ ...ans, code: props.initialCode, allowContact: false })
      }
    })
  }
})

const cartOpen = ref(false)
const cartHolder = ref<HTMLElement | null>(null)
const jobs = computed(() => state.value.jobs)
const codes = computed(() => state.value.codes)

const showJobs = ref<CornerCardJob[]>([])
onMounted(() => (showJobs.value = jobs.value))

const toggleCart = () => {
  cartOpen.value = !cartOpen.value
  showJobs.value = jobs.value
}

const handleClickOutsideBox = (e: MouseEvent) => {
  const parent = cartHolder.value
  const target = e.target as HTMLElement
  if (!e.target) {
    return
  }
  if (!document.body.contains(target)) {
    return
  }
  if (target.closest(".modal")) {
    return
  }
  if (parent && !parent.contains(target)) {
    toggleCart()
  }
}

watch(cartOpen, (newVal) => {
  if (newVal) {
    document.addEventListener("click", handleClickOutsideBox)
  } else {
    document.removeEventListener("click", handleClickOutsideBox)
  }
})
</script>

<style scoped lang="scss">
.button-wrapper {
  position: relative;
  z-index: 1;
}

#bubbles-wrapper {
  width: 4em;
  margin-left: -0.75em;
  position: absolute;
  bottom: calc(100% - 0.7em);
}

@media (min-width: 768px) {
  #cart-holder {
    position: relative;
  }

  #cart-wrapper {
    margin-top: 20px;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(calc(-50% + 25px));
    width: 300px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  #cart-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
}

@media (max-width: 767px) {
  #cart-wrapper {
    top: 100%;
  }
}

#cart-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 0.5em;
  margin-right: -150px;
  background: #fff;
  padding: 1em 1em 0.25em 1em;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1050;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

.strike-through {
  opacity: 0.7;
  text-decoration: line-through;
}

.mono {
  font-family: var(--bs-font-monospace);
}
</style>
