<template lang="pug">
info-banner(
  v-model="hideBanner"
  :phoneVerified="profile.data.phone_verified"
)
quick-info
profile-tab-wrapper(:tabs="tabs" v-model="persistPageInfo.currentTab" style="min-height: 80vh")
  template(v-slot:tab(0))
    .row
      .col-12: .mb-5
        edit-application-profile(
          @submit:makeToast="makeToast($event)"
        )
  template(v-slot:tab(1))
    .row
      .col-12.col-md-6: .mb-5
        skillset(
          :editing="editSkillset"
          @update:editing="editSkillset = $event"
          @submit:makeToast="makeToast($event)"
        )
      .col-12.col-md-6: .mb-5
        job-desire(
          :editing="editJobDesire"
          @update:editing="editJobDesire = $event"
          @submit:makeToast="makeToast($event)"
        )
      .col-12.col-md-6: .mb-5
        education(
          :editing="editEducation"
          @update:editing="editEducation = $event"
          @submit:makeToast="makeToast($event)"
        )
      .col-12.col-md-6: .mb-5
        company-blocklist(
          @submit:makeToast="makeToast($event)"
        )
      .col-12.col-md-6: .mb-5
        recommendation(
          :editing="editRecommendation"
          @update:editing="editRecommendation = $event"
          @submit:makeToast="makeToast($event)"
        )

  template(v-slot:tab(2))
    .mb-5
      chat-board(
        :conversationId="conversationId"
        @error:makeToast="makeToast($event)"
      )
  template(v-slot:tab(3))
    .row
      .col-12: .mb-5
        job-subscription(
          @submit:makeToast="makeToast($event)"
        )
  template(v-slot:tab(4))
    .row: .col-12: .mb-5
      job-board

modal-wrapper
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  watch,
  // nextTick
} from 'vue'

import Skillset from './components/edit/Skillset.vue'
import JobDesire from './components/edit/JobDesire.vue'
import Education from './components/edit/Education.vue'
import Recommendation from "./components/edit/Recommendation.vue"
import EditApplicationProfile from "./components/edit/EditApplicationProfile.vue"
import CompanyBlocklist from './components/edit/CompanyBlocklist.vue'

import QuickInfo from "./components/QuickInfo.vue"
import ProfileTabWrapper from './components/ProfileTabWrapper.vue'
import ModalWrapper from "./components/ModalWrapper.vue"
import InfoBanner from "./components/InfoBanner.vue"
import JobSubscription from "./components/JobSubscription.vue"
import JobBoard from './components/JobBoard.vue'
import ChatBoard from './chat/ChatBoard.vue'

import { talentsProfileDataPath } from "@/routes"
import { jobDesire, profile, applicationProfile, education, persistPageInfo } from "@/talents/profile/profile"

import { useToast, POSITION, TYPE } from "vue-toastification"

const toast = useToast()

const hideBanner = ref<boolean>(false)

const editSkillset = ref<boolean>(false)
const editJobDesire = ref<boolean>(false)
const editEducation = ref<boolean>(false)
const editRecommendation = ref<boolean>(false)

function resetEditing() {
  editSkillset.value = false
  editJobDesire.value = false
  editEducation.value = false
  editRecommendation.value = false
}

function makeToast(payload: { message: string, type: TYPE }) {
  toast(payload.message, {
    position: POSITION.TOP_CENTER,
    type: payload.type,
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  })
}

watch(() => persistPageInfo.value.currentTab, (currentTab) => {
  if (currentTab !== 1) {
    resetEditing()
  }
})

const props = defineProps<{
  tabs: {
    title: string
    icon: string
    hasNotification: boolean
  }[],
  initialTab?: number
  conversationId?: number
}>()

onMounted(() => {
  if (props.initialTab && props.initialTab < props.tabs.length) {
    persistPageInfo.value.currentTab = props.initialTab
  }
  const path = talentsProfileDataPath()
  fetch(path)
    .then((response: any) => response.json())
    .then((data: any) => {
      profile.value = data.profile
      applicationProfile.value = data.application_profile
      jobDesire.value = data.job_desire
      education.value = data.education
    })
})
</script>


<style>
h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
}

.skeleton-box {
  width: 100%;
  min-height: 250px;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
}
.skeleton-box--image {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  animation: pulse 1.5s infinite;
}
.skeleton-box--title {
  width: 100%;
  margin-top: 1rem;
  height: 30px;
  animation: pulse 1.5s infinite;
}
.skeleton-box--content {
  width: 100%;
  height: 20px;
  margin-top: 1rem;
  animation: pulse 1.5s infinite;
}
.skeleton-box--footer {
  width: 100%;
  height: 40px;
  margin-top: 3.25rem;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.profile-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blurred {
  filter: blur(5px);
}
.no-transform {
  text-transform: none !important;
}
.profile-card {
  border: 1px solid #bfc5cc;
  border-radius: 0.5rem;
}

.profile-card-title {
  color: #103640;
  font-weight: 600;
  font-size: 1.2rem;
}

.open-modal {
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: underline;
}

.open-modal:hover {
  color: #3b82f6;
}

.row-header {
  font-weight: bold;
  font-size: 1.2rem;
  color: #103640;
}

.card-footer-sticky {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #f3f3f3;
}

.modal--footer {
  z-index: 9999;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  5% { transform: rotate(5deg); }
  10% { transform: rotate(0eg); }
  15% { transform: rotate(-5deg); }
  20% { transform: rotate(0deg); }
}

.tilt-shaking {
  animation: tilt-shaking 2s infinite;
}

</style>
