<template lang="pug">
.row.my-5
  .col-12.col-lg-8.mb-3.text-left
    | {{ $t("js.components.cart_overview.explain") }}

    div
      a.text-decoration-underline(href="https://www.empfehlungsbund.de/datenschutz#bewerbungsformular")
        | {{ $t("js.components.cart_overview.privacy_policy") }}
  .col-12.col-lg-4.mb-3
    div(class='d-flex justify-content-end mb-3')
      button(class="btn btn-danger btn-sm" @click.prevent="deleteAll()")
        | {{ $t('js.components.cart.cart_overview.alle_eingaben_von_diesem_g') }}


.row
  .col-12.col-lg-8.mb-3
    PersonalData(:form='form')
  .col-12.col-lg-4.mb-3
    .card.card-body
      .mb-3(v-if="codes.length === 0")
        h3
          | {{ $t('js.components.cart.codes_empty') }}

      .mb-3(v-else)
        h3.h4.font-normal
          |  {{ $t('js.components.cart.codes') }}

        .my-3.d-flex.gap-2.flex-column(v-if="codes.length > 0")
          span(class="badge w-100 d-flex gap-2 flex-row bg-dark align-items-center justify-content-between bg-opacity-50 border-1 border border-dark" v-for="code in codes")
            |  {{ code.code }}
            img(:src="code.logo" v-if="code.logo" style='max-height: 3.0em; margin-left: -5px' class='rounded')

      div(style='max-width: 400px')
        CodesModal

.card(v-if="jobs.length === 0")
  .card-body: h3
    | {{ $t('js.components.cart.jobs_empty') }}
  .card-footer
    a.btn.btn-portal(:href="jobsSearchPath()")
      | {{ $t('js.components.cart.jobs_empty_button') }}
div(v-else)
  h3.h3.mb-4
    | {{ $t('js.components.cart.jobs') }}
  .d-flex.gap-3.flex-column
    JobListItem(
      v-for="job in combinedJobInfos"
      :job="job"
      :organisation='job.organisation'
      :key="job.id"
    )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { Button } from "@/elements"
import { state } from "@/stores/cartStore"
import CodesModal from "@/components/shopping-cart-codes-modal.vue"
import PersonalData from "@/components/cart/personal-data.vue"
import JobListItem from "@/wizard/JobListItem.vue"
import useApply from "@/components/apply/useApply"
import { jobsSearchPath } from "@/routes"
import { clearCart } from "@/stores/cartStore"
const jobs = computed(() => state.value.jobs)
const codes = computed(() => state.value.codes)
const { form, resetForm } = useApply("")



const jobInfos = ref<merkliste.Job[] | null>([])

const combinedJobInfos = computed<merkliste.CombinedJobInfo[]>(() => {
  return jobs.value.map((j) => {
    if (jobInfos.value == null) {
      return { ...j, loading: true, visible: undefined }
    }
    let info = jobInfos.value.find((i) => i.id == j.id)
    if (info)
      return { ...j, ...info, loading: false, visible: true, bookmark_json: info.bookmark_json, organisation: info.organisation } as merkliste.CombinedJobInfo
    else
      return { ...j, visible: false, loading: false }
  })
})

onMounted(() => {
  fetch("/merkliste.json?job_ids=" + jobs.value.map((j) => j.id).join(","))
    .then((res) => res.json())
    .then((data) => {
      jobInfos.value = data
    })
})

const deleteAll = () => {
  clearCart()
  resetForm()
}

</script>

<style scoped lang="scss">
.button-wrapper {
  position: relative;
  z-index: 1;
}

.job-card {
  width: 100%;
  margin-bottom: 1rem;
}

#bubbles-wrapper {
  left: 1.5em;
  position: absolute;
  bottom: calc(100% - 0.7em);
}

@media (min-width: 768px) {
  #cart-holder {
    position: relative;
  }

  #cart-wrapper {
    margin-top: 20px;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(calc(-50% + 25px));
    width: 300px;
  }

  #cart-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  #cart-outter {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  #cart-wrapper {
    top: 100%;
  }
}

#cart-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 0.5em;
  margin-right: -150px;
  background: #fff;
  padding: 1em 1em 0.25em 1em;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

.strike-through {
  opacity: 0.7;
}

.strike-through p {
  text-decoration: line-through;
}
</style>
