<template lang="pug">
.card.fit-content
  .photo--container
    img(
      :src='person.photo_url'
      alt="Photo"
    )
  br
  .card-body
    | {{ person.to_display_format }}
    br
    small.text-muted
      | {{person.position}}
    div: div.btn-group.mt-2
      a.btn.btn-sm.btn-outline-dark(:href='person.profile_url' v-if='person.profile_url' target='_blank')
        Icon(v-if='person.profile_url.includes("linkedin")' :path='mdiLinkedin' :size='14')
        span(v-else)
          |XING
      a.btn.btn-sm.btn-outline-dark(:href='mailTo' v-if='person.email' target='_blank')
        Icon(:path='mdiAt' :size='14')
      a.btn.btn-sm.btn-outline-dark(:href='telTo' v-if='person.telephone')
        Icon(:path='mdiPhone' :size='14')
</template>

<script lang="ts" setup>
import { mdiLinkedin, mdiAt, mdiPhone } from "@mdi/js"
import Icon from "@/elements/Icon"

import { computed } from "vue"

const props = defineProps<{
  person: frontend.Organisation["visible_people"][0]
}>()

const mailTo = computed(() => {
  return `mailto:${props.person.email}?subject=Stellenanzeige%20auf%20Empfehlungsbund.de`
})

const telTo = computed(() => {
  if (props.person.telephone) {
    return `tel:${props.person.telephone.replace(/[^\d]/g, "")}`
  }
})
</script>

<style scoped>
img {
  max-width: 200px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
